<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>{{ title }}</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <el-form ref="form" :model="form" :rules="formRules" label-width="6rem" @submit.native.prevent>
          <el-form-item label="操作">
            <el-radio-group v-model.number="action" @change="init">
              <el-radio-button
                v-for="action in actions"
                :key="action.value"
                :label="action.value"
              >{{ action.label }}</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="郵包號" prop="parcel_code">
            <el-input
              ref="parcelCodeInput"
              v-model="form.parcel_code"
              placeholder="請掃描/輸入郵包號獲取訂單"
              clearable
              autofocus
              :disabled="order !== null"
              @keyup.enter.native="getOrder"
              @clear="init"
            ></el-input>
          </el-form-item>
        </el-form>

        <div v-if="order && action !== 3">
          <el-form
            ref="orderForm"
            :model="orderForm"
            :rules="orderFormRules"
            label-width="6rem"
            @submit.native.prevent
          >
            <el-form-item label="運輸渠道" prop="ship_way">
              <el-radio-group v-model="orderForm.ship_way">
                <el-radio-button
                  v-for="ship_way in order.ship_ways"
                  :key="ship_way.value"
                  :label="ship_way.value"
                >
                  {{ ship_way.label }}
                </el-radio-button>
<!--                <el-radio-button label="ETK"></el-radio-button>-->
<!--                <el-radio-button label="ETK-AM"></el-radio-button>-->
<!--                <el-radio-button label="EMS">流花CC</el-radio-button>-->
<!--                <el-radio-button label="ZTO-CC-2">中通個物2號線</el-radio-button>-->
              </el-radio-group>
            </el-form-item>

            <el-form-item label="商品類型" prop="goodsType" v-show="orderForm.ship_way === 'HKPC' || orderForm.ship_way === 'ZTO-HKPC'">
              <el-radio-group v-model="orderForm.goodsType">
                <el-radio-button label="G">禮物</el-radio-button>
                <el-radio-button label="D">文件</el-radio-button>
                <el-radio-button label="S">商業樣品</el-radio-button>
                <el-radio-button label="R">退貨</el-radio-button>
                <el-radio-button label="M">商品銷售</el-radio-button>
                <el-radio-button label="O">其他</el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item
              label="商品類型"
              prop="goodsTypeDesc"
              v-show="(orderForm.ship_way === 'HKPC' || orderForm.ship_way === 'ZTO-HKPC') && orderForm.goodsType === 'O'"
            >
              <el-input v-model="orderForm.goodsTypeDesc" />
            </el-form-item>

            <el-form-item label="预付税金" prop="duty_paid">
              <el-switch
                v-model="orderForm.duty_paid"
                active-text="是"
                inactive-text="否">
              </el-switch>
            </el-form-item>

            <el-form-item label="重量(kg)" prop="total_weight">
              <el-input
                ref="weightingInput"
                v-model="orderForm.total_weight"
                @keyup.enter.native="weighting"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="weighting" :disabled="submitDisabled">提交</el-button>
              <el-button type="danger" @click="cancel">取消</el-button>
            </el-form-item>
          </el-form>

          <el-alert type="warning" v-if="order.unpaid_additional_fees_count" :closable="false">
            該訂單有{{ order.unpaid_additional_fees_count }}筆附加費尚未支付
          </el-alert>
        </div>
      </div>
    </div>

    <div class="row" v-if="order">
      <div class="col-sm-12 col-lg-4">
        <div class="card card-default h-100">
          <div class="card-header">
            <div class="card-title">訂單信息</div>
          </div>
          <div class="card-body">
            <order-info-table
              class="table-striped"
              :order="order"
              @updateOrder="(newOrder) => order = newOrder"
              @cancelAdditionalFee="handleCancelAdditionalFee"
            >
            </order-info-table>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-8">
        <div class="card card-default h-100">
          <div class="card-header">
            <div class="card-title">訂單商品</div>
          </div>
          <div class="card-body">
            <order-items-table
              class="table-striped"
              :order="order"
              @updateOrderItem="handleUpdateOrderItem"
              @deleteOrderItem="handleDeleteOrderItem"
            />
          </div>
        </div>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import _ from 'lodash';
import printJS from 'print-js';
// import { getLodop } from '@/LodopFuncs';
import { getLoadingInstance, showErrorMessage } from '@/helpers';
import OrderInfoTable from '@/components/Order/OrderInfoTable';
import OrderItemsTable from '@/components/Order/OrderItemsTable';

export default {
  name: 'WeightingAndPrintLabel',
  data() {
    return {
      actions: [
        {
          label: '稱重並打單',
          value: 1,
        },
        {
          label: '只称重',
          value: 2,
        },
        {
          label: '只打單',
          value: 3,
        },
      ],
      action: 1,
      form: {
        parcel_code: '',
      },
      formRules: {
        parcel_code: [
          { required: true, message: '請輸入郵包號', trigger: 'blur' },
        ],
      },
      order: null,
      orderForm: {
        ship_way: 'ETK',
        total_weight: null,
        duty_paid: false,
        goodsType: 'M',
        goodsTypeDesc: '',
      },
      orderFormRules: {
        total_weight: {
          validator(rule, value, callback) {
            if (value === '') {
              callback(new Error('請輸入重量'));
            } else if (!_.isNumber(Number(value)) || value <= 0) {
              callback(new Error('輸入的重量不正確'));
            } else {
              callback();
            }
          },
          trigger: 'blur',
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    title() {
      const action = _.find(this.actions, { value: this.action });
      return action.label;
    },
    submitDisabled() {
      if (this.order && this.order.unpaid_additional_fees_count) {
        return true;
      }
      return false;
    },
  },
  watch: {
    '$route.params.parcel_code': {
      handler() {
        this.init();
      },
    },
  },
  methods: {
    init() {
      if (this.$route.params.parcel_code) {
        this.form.parcel_code = this.$route.params.parcel_code;
        this.getOrder();
      } else {
        this.form.parcel_code = '';
        this.order = null;
        this.$refs.parcelCodeInput.focus();
      }
      this.orderForm.total_weight = null;
      this.orderForm.duty_paid = false;
    },
    cancel() {
      if (this.$route.params.parcel_code) {
        this.$router.replace({ name: 'weighting-print-label', params: {} });
      }
      this.init();
    },
    getOrder() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          this.$api.order.getByParcelCode(this.form.parcel_code).then(({ data: response }) => {
            this.order = response.data;
            this.$nextTick(() => {
              if (this.order.issue) {
                this.$notify.warning({
                  title: '這個是問題訂單',
                });
                this.init();
              }
              if (this.order.status === 3) {
                /* eslint-disable no-lonely-if */
                if (this.action === 3) {
                  // 只打單
                  if (this.order.label_url && this.order.total_weight) {
                    this.printLabel(this.order.label_url);
                  } else {
                    this.$notify.warning({
                      title: '請先完成稱重操作',
                    });
                    this.init();
                  }
                } else if (this.order.total_weight > 0) {
                  // 稱重並打單 || 只稱重
                  this.$notify.warning({
                    title: '該訂單已完成稱重',
                  });
                  if (this.$route.params.parcel_code) {
                    this.$router.push({ name: 'weighting-print-label' });
                    return;
                  }
                  this.$route.params.parcel_code = '';
                  this.form.parcel_code = '';
                  this.order = null;
                  this.$refs.parcelCodeInput.focus();
                } else {
                  // this.$refs.weightingInput.focus();
                }
              } else {
                switch (this.order.status) {
                  case 1:
                    this.$notify.warning({
                      title: '該訂單還沒支付',
                    });
                    break;
                  case 2:
                    this.$notify.warning({
                      title: '該訂單還沒完成攬收',
                    });
                    break;
                  case 4:
                    this.$notify.warning({
                      title: '該訂單已完成稱重',
                    });
                    if (this.$route.params.parcel_code) {
                      this.$router.push({ name: 'weighting-print-label' });
                      return;
                    }
                    break;
                  case 5:
                    this.$notify.warning({
                      title: '該訂單已完成',
                    });
                    break;
                  default:
                    this.$notify.warning({
                      title: '該訂單目前不能稱重',
                    });
                    break;
                }
                this.init();
              }
            });
          }).catch((errors) => {
            this.order = null;
            showErrorMessage(errors);
            this.form.parcel_code = '';
            this.$refs.parcelCodeInput.focus();
          }).finally(() => {
            loading.close();
          });
        }
      });
    },
    weighting() {
      if (this.order.unpaid_additional_fees_count) {
        this.$notify.warning({
          title: `該訂單有${this.order.unpaid_additional_fees_count}筆附加費尚未支付`,
        });
        return;
      }
      // ems判断商品英文名
      /* eslint-disable no-extra-boolean-cast */
      if (this.orderForm.ship_way === 'EMS') {
        const names = [];
        const units = [];
        this.order.order_items.forEach((item) => {
          if (!item.product_name_en) {
            names.push(item.product_name);
          }
          console.log(Object.keys(item.units).length);
          if (Object.keys(item.units).length > 1) {
            units.push(item.product_name);
          }
        });
        if (names.length) {
          this.$notify.warning({
            title: `流花CC、港郵專線渠道需補充填寫${names.splice(',')}商品英文名`,
          });
          return;
        }
        if (units.length) {
          this.$notify.warning({
            title: `流花CC、港郵專線渠渠道需補充填寫${names.splice(',')}的商品計量編碼`,
          });
          return;
        }
      }

      if (this.order.version === '1') {
        if (this.orderForm.total_weight > this.order.parcel_model.max_weight) {
          // 超重
          const palcelModelName = this.order.parcel_model.name;
          this.$confirm(`該郵包的重量超過【${palcelModelName}】的最大重量。是否繼續？`, '提示', {
            confirmButtonText: '繼續',
            cancelButtonText: '重新稱重',
            type: 'warning',
          }).then(() => {
            this.weightingSubmit();
          }).catch(() => {
            this.orderForm.total_weight = null;
            this.$refs.weightingInput.focus();
          });
        } else {
          this.weightingSubmit();
        }
      }

      if (this.order.version === '2') {
        if (this.orderForm.total_weight > this.order.weight) {
          this.$confirm('實際重量大於申報重量，是否繼續操作？', '提示', {
            type: 'warning',
          }).then(() => {
            this.weightingSubmit();
          }).catch(() => {
            this.orderForm.total_weight = null;
            this.$refs.weightingInput.focus();
          });
        } else {
          this.weightingSubmit();
        }
      }
    },
    weightingSubmit() {
      this.$refs.orderForm.validate((valid) => {
        if (valid) {
          const params = {
            ship_way: this.orderForm.ship_way,
            total_weight: this.orderForm.total_weight,
            duty_paid: this.orderForm.duty_paid,
            goodsType: this.orderForm.goodsType,
            goodsTypeDesc: this.orderForm.goodsTypeDesc,
          };
          if (!this.order.tracking_no) {
            params.action = 'push';
          }
          const loading = getLoadingInstance();
          this.$api.order.update(this.order.id, params).then(({ data: response }) => {
            this.$nextTick(() => {
              this.order = response.data;
              if (this.action === 1) {
                // 稱重並打單
                this.printLabel(this.order.label_url);
              }
              this.init();
            });
          }).catch(errors => showErrorMessage(errors)).finally(() => {
            loading.close();
          });
        }
      });
    },
    printLabel(url) {
      this.$api.order.update(this.order.id, { action: 'print_label' }).then(() => {
        printJS({
          printable: url,
          type: 'pdf',
          showModal: true,
        });
        /*
        const LODOP = getLodop();
        if (false) {
          LODOP.PRINT_INIT('');
          LODOP.NewPage();
          LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', url);
          LODOP.SET_PREVIEW_WINDOW(0, 0, 0, 0, 0, '');
          LODOP.PRINT();
        } else {
          printJS({
            printable: url,
            type: 'pdf',
            showModal: true,
          });
        }
         */
      });
    },
    handleUpdateOrderItem(item) {
      const index = _.findIndex(this.order.order_items, { id: item.id });
      if (index !== -1) {
        this.order.order_items[index] = item;
      } else {
        this.order.order_items.push(item);
      }
    },
    handleDeleteOrderItem(id) {
      this.order.order_items = _.reject(this.order.order_items, { id });
    },
    handleCancelAdditionalFee(id) {
      this.order.additional_fees = _.reject(this.order.additional_fees, { id });
    },
  },
  components: {
    OrderInfoTable,
    OrderItemsTable,
  },
};
</script>
