<template>
  <div class="order-items-table">
    <table class="table table-bordered">
      <tbody>
        <tr>
          <th>商品品名</th>
          <th>商品英文名</th>
          <th>商品分類</th>
          <th>計量單位</th>
          <th>行郵稅號</th>
          <th>完稅價格</th>
          <th>稅率</th>
          <th>數量</th>
          <th>商品價格</th>
          <th>税金</th>
          <th>毛重(kg)</th>
          <th v-if="orderEditable"></th>
          <!--<th>商品原產國</th>-->
        </tr>
        <tr v-for="item in order.order_items" :key="item.id">
          <td>{{ item.product_name }}</td>
          <td>{{ item.product_name_en }}</td>
          <td>{{ item.product_category ? item.product_category.name : '' }}</td>
          <td>{{ item.unit }} {{ item.unit_code ? `(${item.unit_code})` : '' }}</td>
          <td>{{ item.product_category ? item.product_category.postal_tax_number : '' }}</td>
          <td>
            <template v-if="item.product_category">
              <span v-if="item.product_category.dutiable_value">
                {{ item.product_category.dutiable_value | currency('CNY') }}
                <span v-if="item.unit"> / {{ item.unit }}</span>
              </span>
              <span v-else>另行確定</span>
            </template>
          </td>
          <td>{{ item.product_category ? `${item.product_category.tax_rate * 100}%` : '' }}</td>
          <td>{{ item.product_count }}{{ item.unit }}</td>
          <td>{{ item.price | currency(item.currency) }}</td>
          <td>
            <template v-if="item.tax">{{ item.tax | currency }}</template>
            <template v-else>-</template>
          </td>
          <td>{{ item.gross_weight }}</td>
          <td v-if="orderEditable">
            <el-button type="primary" size="mini" @click="editItem(item)">編輯</el-button>
            <el-button type="info" size="mini" @click="editItem(item, true)">複製</el-button>
            <el-button type="danger" size="mini" @click="deleteProductItem(item.id)">刪除</el-button>
          </td>
          <!--<td>{{ item.origin_country }}</td>-->
        </tr>
        <tr v-if="orderEditable">
          <td colspan="10"></td>
          <td>
            <el-button type="primary" size="mini" @click="addItem" v-if="orderEditable">添加商品</el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <el-dialog
      :title="editItemForm && editItemForm.id ? '編輯訂單商品' : '添加訂單商品'"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      @close="() => editItemForm = null"
    >
      <el-form ref="editItemForm" v-if="editItemForm" :model="editItemForm" :rules="editItemFormRules" label-width="8rem">
        <el-form-item label="商品品名" prop="product_name">
          <el-input v-model="editItemForm.product_name" clearable placeholder="請輸入商品品名" />
        </el-form-item>
        <el-form-item label="商品英文名" prop="product_name_en">
          <el-input v-model="editItemForm.product_name_en" clearable placeholder="請輸入商品商品英文名" />
          <span class="text-warning">流花CC需補充填寫商品英文名</span>
        </el-form-item>
        <el-form-item label="商品分類" prop="product_category_id">
          <el-cascader
            ref="productCategroyCascader"
            class="w-100"
            v-model="editItemForm.product_category_ids"
            :options="productCategories"
            :props="{
              expandTrigger: 'hover',
              value: 'id',
              label: 'name',
            }"
            filterable
            @change="handleChangeCategory"
          />
        </el-form-item>
        <template v-if="Object.keys(editItemForm.units).length > 1">
          <el-form-item label="商品計量編碼" prop="unit_code">
            <el-select class="ml-3" v-model="editItemForm.unit_code">
              <el-option
                  v-for="(unit, key) in editItemForm.units"
                  :key="key"
                  :label="unit"
                  :value="key"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="商品計量編碼" prop="unit_code">
            <el-input v-model="editItemForm.unit_code" clearable placeholder="請輸入商品計量編碼" />
          </el-form-item>
        </template>
        <!--<el-form-item label="商品計量編碼" prop="unit_code">-->
          <!--<el-input v-model="editItemForm.unit_code" clearable placeholder="請輸入商品計量編碼" />-->
        <!--</el-form-item>-->
        <el-form-item v-if="editItemForm.product_category">
          <table class="table">
            <tbody>
              <tr>
                <th>行郵稅號</th>
                <th>完稅價格</th>
                <th>稅率</th>
              </tr>
              <tr>
                <td>{{ editItemForm.product_category.postal_tax_number }}</td>
                <td>
                  <span v-if="editItemForm.product_category.dutiable_value">
                    {{ editItemForm.product_category.dutiable_value | currency('CNY') }}
                    <span v-if="editItemForm.product_category.unit"> / {{ editItemForm.product_category.unit }}</span>
                  </span>
                  <span v-else>另行確定</span>
                </td>
                <td>{{ editItemForm.product_category ? `${editItemForm.product_category.tax_rate * 100}%` : '' }}</td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
        <el-form-item label="商品價格" prop="price">
          <el-input-number v-model="editItemForm.price" :min="0" :precision="2" />
          <el-select class="ml-3" v-model="editItemForm.currency">
            <el-option
              v-for="currency in currencies"
              :key="currency"
              :label="currency"
              :value="currency"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="數量" prop="product_count">
          <el-input-number v-model="editItemForm.product_count" :min="1" />
          {{ editItemForm.product_category ? editItemForm.product_category.unit : '' }}
        </el-form-item>
        <el-form-item label="毛重" prop="gross_weight">
          <el-input-number v-model="editItemForm.gross_weight" :min="0.1" :step="0.1" :precision="1" /> KG
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveProductItem">確定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { Probability, getLoadingInstance, showErrorMessage } from '@/helpers';

export default {
  name: 'OrderItemsTable',
  props: {
    order: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    (new Probability({
      p: this.productCategories.length ? '50%' : '100%',
      f: () => this.$store.dispatch('fetchProductCategories'),
    }))();
  },
  data() {
    return {
      currencies: ['HKD', 'RMB', 'USD', 'EUR', 'GBP', 'JPY'],
      dialogVisible: false,
      editItemForm: null,
      editItemFormRules: {
        product_name: { required: true, message: '請輸入商品品名', trigger: 'blur' },
        unit_code: { required: true, message: '請輸入商品計量單位', trigger: 'blur' },
        price: { required: true, message: '請輸入價格', trigger: 'blur' },
        gross_weight: { required: true, message: '請輸入重量', trigger: 'blur' },
        product_count: { required: true, message: '請輸入数量', trigger: 'blur' },
        product_category_id: { required: true, message: '請選擇商品分類', trigger: 'change' },
      },
    };
  },
  computed: {
    ...mapState({
      productCategories: state => state.order.productCategories,
    }),
    orderEditable() {
      return this.order.status === 1 || this.order.status === 2 || this.order.status === 3;
    },
  },
  methods: {
    addItem() {
      this.editItemForm = {
        id: null,
        product_name: '',
        unit_code: '',
        product_name_en: '',
        product_category_id: null,
        product_category_ids: [],
        product_category: null,
        price: 0,
        currency: 'HKD',
        gross_weight: 0.1,
        product_count: 1,
        units: [],
      };
      this.dialogVisible = true;
    },
    editItem(item, copy = false) {
      this.editItemForm = {
        product_name: item.product_name,
        product_name_en: item.product_name_en,
        unit_code: Object.keys(item.units).length > 1 ? '' : item.unit_code,
        product_category_id: item.product_category ? item.product_category.id : null,
        product_category_ids: [],
        product_category: item.product_category ? _.clone(item.product_category) : null,
        price: item.price,
        currency: item.currency,
        gross_weight: item.gross_weight,
        product_count: item.product_count,
        units: item.units,
      };
      console.log(item);
      if (!copy) {
        this.editItemForm.id = item.id;
      }
      if (item.product_category) {
        _.forEach(item.product_category.node.split(','), (id) => {
          if (id) {
            this.editItemForm.product_category_ids.push(Number(id));
          }
        });
      }
      this.dialogVisible = true;
    },
    handleChangeCategory() {
      const checkedNodes = this.$refs.productCategroyCascader.getCheckedNodes(true);
      this.editItemForm.product_category = checkedNodes[0].data;
      this.editItemForm.product_category_id = this.editItemForm.product_category.id;
    },
    saveProductItem() {
      this.$refs.editItemForm.validate((valid) => {
        if (valid) {
          const params = {
            product_name: this.editItemForm.product_name,
            product_name_en: this.editItemForm.product_name_en,
            unit_code: this.editItemForm.unit_code,
            product_count: this.editItemForm.product_count,
            gross_weight: this.editItemForm.gross_weight,
            price: this.editItemForm.price,
            currency: this.editItemForm.currency,
            order_id: this.order.id,
            product_category_id: this.editItemForm.product_category_id,
            unit: this.editItemForm.product_category.unit,
            post_tax_num: this.editItemForm.product_category.postal_tax_number,
          };
          if (this.editItemForm.id) {
            params.id = this.editItemForm.id;
          }
          const loading = getLoadingInstance();
          this.$api.order.saveItem(params).then(({ data: response }) => {
            this.$emit('updateOrderItem', response.data);
          }).catch((e) => {
            showErrorMessage(e);
          }).finally(() => {
            loading.close();
            this.dialogVisible = false;
          });
        }
      });
    },
    deleteProductItem(id) {
      this.$confirm('確定要刪除這個物品嗎？', '提示', {
        type: 'warning',
      }).then(() => {
        this.$api.order.deleteItem(id).then(() => {
          this.$emit('deleteOrderItem', id);
        });
      });
    },
  },
};
</script>
