<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>訂單確認攬件</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <el-form ref="form" :model="form" :rules="formRules" label-width="6rem" @submit.native.prevent>
          <el-form-item label="郵包號" prop="parcel_code">
            <el-input
              ref="parcelCodeInput"
              v-model="form.parcel_code"
              placeholder="請掃描/輸入郵包號"
              clearable
              autofocus
              @keyup.enter.native="confirm"
              @clear="init"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </ContentWrapper>
</template>

<script>
import { getLoadingInstance, showErrorMessage } from '@/helpers';

export default {
  name: 'OrderSplit',
  data() {
    return {
      form: {
        parcel_code: '',
      },
      formRules: {
        parcel_code: [
          { required: true, message: '請輸入郵包號', trigger: 'blur' },
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
  },
  watch: {
    '$route.params.parcel_code': {
      handler() {
        this.init();
      },
    },
  },
  methods: {
    init() {
      if (this.$route.params.parcel_code) {
        this.form.parcel_code = this.$route.params.parcel_code;
        this.confirm();
      } else {
        this.form.parcel_code = '';
        this.$refs.parcelCodeInput.focus();
      }
    },
    confirm() {
      const loading = getLoadingInstance();
      this.$api.order.pickup(this.form.parcel_code).then(() => {
        this.$notify.success({
          title: '操作成功',
        });
      }).catch(error => showErrorMessage(error)).finally(() => {
        loading.close();
      });
    },
  },
  components: {
  },
};
</script>

<style lang="scss">
  .order-split-dialog {
    .el-transfer-panel {
      width: 40%;
    }
  }
</style>
