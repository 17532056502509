<template>
  <ContentWrapper id="order-issues">
    <div class="content-heading">
      訂單詳細
    </div>

    <el-card>
      <template v-if="order && order.address">

        <table class="table">
          <tbody>
          <tr>
            <th>寄件人姓名</th>
            <td>{{ order.address.contact }}</td>
          </tr>
          <tr>
            <th>寄件人電話</th>
            <td>{{ order.address.phone }}</td>
          </tr>
          <tr>
            <th>寄件人地址</th>
            <td>{{ order.address.pickup_address }}</td>
          </tr>
          <tr v-if="order.status < 3">
            <th></th>
            <td>
              <el-button type="primary" class="ml-2" size="small" @click="openChangeShipping"
                         v-if="order.status < 4">修改地址信息</el-button>
            </td>
          </tr>

          <tr>
            <th>用戶填寫取件時間</th>
            <td>{{ order.date }}  {{ order.time_period }}</td>
          </tr>

          <tr>
            <th>用戶備註</th>
            <td>{{ order.remark }}</td>
          </tr>

          <tr>
            <th>安排寄件時間</th>
            <td>{{ order.pickup_time }}</td>
          </tr>

          <tr>
            <th>寄件備註</th>
            <td>{{ order.admin_remark }}</td>
          </tr>

          </tbody>
        </table>

      </template>

    </el-card>

    <el-card class="mt-3" v-if="order && order.orders">
      <div slot="header" class="clearfix">訂單列表</div>

      <table class="table table-striped">
        <tbody>
        <tr>
          <th>郵包號</th>
          <th>狀態</th>
          <th></th>
        </tr>
        <tr v-for="item in order.orders" :key="item.id">
          <td>
            {{ item.parcel_code }}
          </td>
          <td>
            {{ orderStatusesObj[item.status] ? orderStatusesObj[item.status].label : '' }}
          </td>
          <td>
            <router-link class="btn btn-primary" :to="{ name: 'order-detail', params: { id: item.id }}">
              詳情
            </router-link></td>
        </tr>
        </tbody>
      </table>
    </el-card>

    <div class="card card-default mt-3" v-if="order">
      <div class="card-body">
        <el-tabs tab-position="left" v-model="activeTab" @tab-click="handleClick">
          <el-tab-pane label="訂單日誌" name="log">
            <el-timeline class="mt-3" v-if="statusLogs">
              <el-timeline-item
                v-for="log in statusLogs"
                :key="log.id"
                :timestamp="log.created_at"
              >
                <h4>{{ log.action }}</h4>
                <section>{{ log.message }}</section>
              </el-timeline-item>
            </el-timeline>
          </el-tab-pane>
          <!--<el-tab-pane label="物流軌跡" name="tracking">-->
            <!--<el-timeline class="mt-3" placement="top" v-if="trackings">-->
              <!--<el-timeline-item-->
                <!--v-for="(item, key) in trackings"-->
                <!--:key="item.status + key"-->
                <!--:timestamp="item.time"-->
              <!--&gt;-->
                <!--<el-card>-->
                  <!--{{ item.message }}-->
                <!--</el-card>-->
              <!--</el-timeline-item>-->
            <!--</el-timeline>-->
            <!--<span v-else>暫無物流信息</span>-->
          <!--</el-tab-pane>-->
        </el-tabs>
      </div>
    </div>

    <el-dialog
        :visible.sync="changeShippingDialogVisible"
        title="修改地址信息"
        :append-to-body="true"
    >
      <el-form ref="shippingForm" :model="shippingForm" :rules="shippingFormRules" label-width="10rem">

        <el-form-item label="收件地區" prop="district_id_nodes">
          <el-cascader
              class="w-100"
              placeholder="請選擇所在地區"
              v-model="shippingForm.district_id_nodes"
              :options="districts"
              :props="districtsProps"
              @change="getDistrictId"
              filterable
          ></el-cascader>
        </el-form-item>

        <el-form-item prop="address" label="收件地址">
          <el-input v-model="shippingForm.address" placeholder="請輸入收件地址"/>
        </el-form-item>

        <el-form-item prop="contact" label="寄件人姓名">
          <el-input v-model="shippingForm.contact" placeholder="請輸入寄件人姓名" />
        </el-form-item>
        <el-form-item label="寄件人電話" prop="sender_phone">
          <el-input v-model.trim="shippingForm.sender_phone" placeholder="請輸入寄件人電話">
            <el-select v-model="shippingForm.sender_phone_area_code" placeholder="手机区号"
                       slot="prepend" style="width: 150px">
              <el-option v-for="item in areaCodes" :key="item.code" :value="item.value"
                         :label="item.name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeShippingDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveShipping">確定</el-button>
      </span>
    </el-dialog>
  </ContentWrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import { getLoadingInstance, showErrorMessage } from '@/helpers';
// import OrderInfoTable from '@/components/Order/OrderInfoTable';
// import OrderItemsTable from '@/components/Order/OrderItemsTable';
import _ from 'lodash';

export default {
  name: 'OrderDetail',
  data() {
    return {
      districts: [],
      districtsProps: {
        value: 'id',
        label: 'name',
        children: 'children',
      },
      shippingFormRules: {
        contact: { required: true, message: '請輸入寄件人姓名', trigger: 'blur' },
        sender_phone: { required: true, message: '請輸入寄件人電話', trigger: 'blur' },
      },
      shippingForm: {
        contact: '',
        sender_phone_area_code: '',
        sender_phone: '',
      },
      areaCodes: [
        {
          name: '香港 +852',
          value: '+852',
        },
        {
          name: '中國大陸 +86',
          value: '+86',
        },
      ],
      changeShippingDialogVisible: false,
      order: null,
      activeTab: 'log',
      statusLogs: null,
      trackings: null,
    };
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        this.getOrder(id);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters([
      'pickupOrderStatusesObj',
      'orderStatusesObj',
    ]),
  },
  mounted() {
    this.$api.district.tree()
      .then(({ data: response }) => {
        this.districts = response;
      });
  },
  methods: {
    getDistrictId() {
      if (this.shippingForm.district_id_nodes.length) {
        const index = this.shippingForm.district_id_nodes.length - 1;
        this.shippingForm.district_id = this.shippingForm.district_id_nodes[index];
      }
    },
    openChangeShipping() {
      this.changeShippingDialogVisible = true;
      this.shippingForm = {
        contact: this.order.address.contact,
        sender_phone_area_code: this.order.address.phone.split(' ')[0],
        sender_phone: this.order.address.phone.split(' ')[1],
        district_id: this.order.address.district_id,
        district_id_nodes: this.order.address.district_id_nodes,
        address: this.order.address.address,
      };
    },
    saveShipping() {
      this.$refs.shippingForm.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          this.$api.pickupOrder.update(this.order.id, { ...this.shippingForm, action: 'change_shipping' }).then(({ data: response }) => {
            // this.$emit('updateOrder', response.data);
            this.order = response.data;
            this.$notify.success({
              message: '修改成功',
            });
          }).catch((e) => {
            showErrorMessage(e);
          }).finally(() => {
            loading.close();
            this.changeShippingDialogVisible = false;
          });
        }
      });
    },
    handleClick({ name }) {
      if (name === 'tracking' && !this.trackings) {
        const loading = getLoadingInstance();
        this.$api.pickupOrder.tracking(this.order.id).then(({ data: response }) => {
          this.trackings = [...response];
        }).catch(errors => showErrorMessage(errors)).finally(() => {
          loading.close();
        });
      }
    },
    getOrder(id) {
      const loading = getLoadingInstance();
      this.$api.pickupOrder.getById(id).then(({ data: response }) => {
        this.order = response.data;
        this.getStatusLog(id);
      }).catch(erroes => showErrorMessage(erroes)).finally(() => {
        loading.close();
      });
    },
    getStatusLog(id) {
      this.$api.pickupOrder.statusLogs(id).then(({ data: response }) => {
        this.statusLogs = response.data;
      });
    },
    handleUpdateOrderItem(item) {
      const index = _.findIndex(this.order.order_items, { id: item.id });
      if (index !== -1) {
        this.order.order_items[index] = item;
      } else {
        this.order.order_items.push(item);
      }
    },
    handleDeleteOrderItem(id) {
      this.order.order_items = _.reject(this.order.order_items, { id });
    },
    handleCancelAdditionalFee(id) {
      this.order.additional_fees = _.reject(this.order.additional_fees, { id });
    },
  },
  components: {
    // OrderItemsTable,
    // OrderInfoTable,
  },
};
</script>

<style lang="scss">

</style>
