<template>
  <ContentWrapper id="order-issues">
    <div class="content-heading">
      訂單詳細
    </div>
    <div class="row" v-if="order">
      <div class="col-sm-12 col-lg-4">
        <div class="card card-default h-100">
          <div class="card-header">
            <div class="card-title">訂單信息</div>
          </div>
          <div class="card-body">
            <order-info-table
              class="table-striped"
              :order="order"
              @updateOrder="(newOrder) => order = newOrder"
              @cancelAdditionalFee="handleCancelAdditionalFee"
            >
            </order-info-table>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-8">
        <div class="card card-default h-100">
          <div class="card-header">
            <div class="card-title">訂單商品</div>
          </div>
          <div class="card-body">
            <order-items-table
              class="table-striped"
              :order="order"
              @updateOrderItem="handleUpdateOrderItem"
              @deleteOrderItem="handleDeleteOrderItem"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card card-default mt-3" v-if="order">
      <div class="card-body">
        <el-tabs tab-position="left" v-model="activeTab">
          <el-tab-pane label="訂單日誌" name="log">
            <el-timeline class="mt-3" v-if="statusLogs">
              <el-timeline-item
                v-for="log in statusLogs"
                :key="log.id"
                :timestamp="log.created_at"
              >
                <h4>{{ log.action }}</h4>
                <section>{{ log.message }}</section>
              </el-timeline-item>
            </el-timeline>
          </el-tab-pane>
          <el-tab-pane name="issue" v-if="order.issue">
            <span slot="label" class="text-danger">訂單問題</span>
            <p>
              <b>問題描述：</b> {{ order.issue.question }}
            </p>
            <p>
              <b>提出者：</b> {{ order.issue.question_admin }}
            </p>
          </el-tab-pane>
          <el-tab-pane label="物流軌跡" name="tracking">
            <el-timeline class="mt-3" placement="top" v-if="order.tracks.length">
              <el-timeline-item
                v-for="(item, key) in order.tracks"
                :key="item.status + key"
                :timestamp="item.time"
              >
                <el-card>
                  <span class="text-danger">{{ item.city }}</span> {{ item.message }}
                </el-card>
              </el-timeline-item>
            </el-timeline>
            <span v-else>暫無物流信息</span>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import { getLoadingInstance, showErrorMessage } from '@/helpers';
import OrderInfoTable from '@/components/Order/OrderInfoTable';
import OrderItemsTable from '@/components/Order/OrderItemsTable';
import _ from 'lodash';

export default {
  name: 'OrderDetail',
  data() {
    return {
      order: null,
      activeTab: 'log',
      statusLogs: null,
      trackings: null,
    };
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        this.getOrder(id);
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    handleClick() {
      // if (name === 'tracking' && !this.trackings) {
      //   const loading = getLoadingInstance();
      //   this.$api.order.tracking(this.order.id).then(({ data: response }) => {
      //     this.trackings = [...response];
      //   }).catch(errors => showErrorMessage(errors)).finally(() => {
      //     loading.close();
      //   });
      // }
    },
    getOrder(id) {
      const loading = getLoadingInstance();
      this.$api.order.getById(id).then(({ data: response }) => {
        this.order = response.data;
        this.getStatusLog(id);
      }).catch(erroes => showErrorMessage(erroes)).finally(() => {
        loading.close();
      });
    },
    getStatusLog(id) {
      this.$api.order.statusLogs(id).then(({ data: response }) => {
        this.statusLogs = response.data;
      });
    },
    handleUpdateOrderItem(item) {
      const index = _.findIndex(this.order.order_items, { id: item.id });
      if (index !== -1) {
        this.order.order_items[index] = item;
      } else {
        this.order.order_items.push(item);
      }
    },
    handleDeleteOrderItem(id) {
      this.order.order_items = _.reject(this.order.order_items, { id });
    },
    handleCancelAdditionalFee(id) {
      this.order.additional_fees = _.reject(this.order.additional_fees, { id });
    },
  },
  components: {
    OrderItemsTable,
    OrderInfoTable,
  },
};
</script>

<style lang="scss">

</style>
