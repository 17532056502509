<template>
  <section>
    <OrderFilter ref="orderFilter" @filter="filterOrders" name="completion"></OrderFilter>

    <div class="card card-default">
      <div class="card-body">
        <order-table ref="orderTable" name="completion" :filterParams="filterParams" :columns="tableConfigs.columns" :options="tableConfigs.options"
                     exportable></order-table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderFilter from '@/components/Order/OrderFilter';
import OrderTable from '@/components/Order/OrderServerTable';

export default {
  name: 'OrderCompleted',
  data() {
    return {
      orders: [],
      filterParams: {},
      tableConfigs: {
        columns: [
          'id', 'parcel_code', 'parcel_model', 'post_office', 'tracking_no', 'price', 'user', 'total_weight', 'sync', 'status',
          'created_at', 'duty_paid', 'operation',
        ],
        options: {
          sortable: ['parcel_code', 'parcel_model', 'tracking_no'],
        },
      },
    };
  },
  computed: {
    ...mapGetters(['orderTabsObj']),
  },
  watch: {
    'orderTabsObj.completed.update': {
      handler(value) {
        if (value) {
          this.getOrder();
        }
      },
      immediate: true,
    },
  },
  methods: {
    filterOrders(filterParams) {
      this.filterParams = filterParams;
      this.$refs.orderTable.refresh();
    },
    receiveOrders(orders) {
      this.orders = orders;
    },
    getOrder() {
      this.$refs.orderFilter.filter().then(() => {
        this.$store.commit('setOrderTabUpdate', { name: 'completed', update: false });
      });
    },
  },
  components: {
    OrderFilter,
    OrderTable,
  },
};
</script>
