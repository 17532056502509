+<template>
  <div class="card card-default">
    <div class="card-body">
      <el-form ref="filterForm" :model="filterForm" :inline="true" class="filter-form">
        <el-form-item label="狀態" v-if="filterStatus">
          <el-select v-model="filterForm.status">
            <el-option label="所有訂單" :value="-1"></el-option>
            <el-option
              v-for="item in statuses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="id" label="訂單ID" size="medium">
          <el-input type="textarea" autosize placeholder="請輸入訂單ID，換行隔開" v-model="filterForm.id" clearable />
        </el-form-item>

        <el-form-item prop="parcel_code" label="郵包號">
          <el-input type="textarea" autosize placeholder="請輸入郵包號，換行隔開" v-model="filterForm.parcel_code" clearable />
        </el-form-item>

        <el-form-item prop="reference_id" label="支付訂單號">
          <el-input type="textarea" autosize placeholder="請輸入支付訂單號，換行隔開" v-model="filterForm.reference_id" clearable />
        </el-form-item>

        <el-form-item prop="tracking_no" label="運單號">
          <el-input type="textarea" autosize placeholder="請輸入運單號，換行隔開" v-model="filterForm.tracking_no" clearable />
        </el-form-item>

        <el-form-item label="目的地">
          <el-select v-model="filterForm.district_id" multiple filterable  style="width: 280px">
            <el-option
              v-for="district in districts"
              :key="district.id"
              :value="district.id"
              :label="district.name"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="用戶">
          <el-select v-model="filterForm.user_id" multiple filterable  style="width: 280px">
            <el-option
              v-for="user in users"
              :key="user.id"
              :value="user.id"
              :label="user.nickname"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="post_office_id" label="所屬門店">
          <el-select v-model="filterForm.post_office_ids" multiple filterable  style="width: 280px">
            <el-option
              v-for="postOffice in currentUser.post_offices"
              :key="postOffice.id"
              :value="postOffice.id"
              :label="postOffice.name"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="parcel_model_id" label="郵包模型">
          <el-select v-model="filterForm.parcel_model_ids" multiple>
            <el-option
              v-for="parcelModel in parcelModels"
              :key="parcelModel.id"
              :value="parcelModel.id"
              :label="parcelModel.name"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="date" label="訂單日期">
          <el-date-picker
            v-model="filterForm.date"
            type="daterange"
            align="right"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="date" label="退件狀態" v-if="name === 'return'">
          <el-radio-group v-model="filterForm.returnStatus" @change="filter">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button :label="18">港前退件</el-radio-button>
            <el-radio-button :label="19">港後退件</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item prop="date" label="是否發起退款" v-if="name === 'close'">
          <el-radio-group v-model="filterForm.refund_status" @change="filter">
            <el-radio-button label="-1">全部</el-radio-button>
            <el-radio-button label="1">是</el-radio-button>
            <el-radio-button label="0">否</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item prop="date" label="訂單是否支付" v-if="name === 'close'">
          <el-radio-group v-model="filterForm.payment_status" @change="filter">
            <el-radio-button label="-1">全部</el-radio-button>
            <el-radio-button label="1">是</el-radio-button>
            <el-radio-button label="0">否</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <div class="row">
          <div class="col text-center">
            <el-button type="primary" @click="filter">篩選</el-button>
            <el-button @click="reset">重置</el-button>
          </div>
        </div>

      </el-form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'OrderFilter',
  props: {
    name: {
      type: String,
      default: '',
    },
    status: {
      type: Number,
      default: -1,
    },
    printed: {
      type: Number,
      default: -1,
    },
    issue: Boolean,
    filterStatus: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (!this.parcelModels.length) {
      this.$store.dispatch('fetchParcelModels');
    }

    this.$api.district.root().then(({ data: response }) => {
      this.districts = response.data;
    });

    this.$api.user.get().then(({ data: response }) => {
      this.users = [...response.data];
    });
  },
  data() {
    return {
      districts: [],
      users: [],
      returnStatus: '',
      filterForm: {
        status: this.status,
        issue: this.issue,
        printed: this.printed,
        id: '',
        parcel_code: '',
        tracking_no: '',
        reference_id: '',
        post_office_ids: [],
        parcel_model_ids: [],
        date: [],
        returnStatus: '',
        refund_status: '-1',
        payment_status: '-1',
        district_id: [],
        user_id: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近一個月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近三個月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      statuses: state => state.order.statuses,
      parcelModels: state => state.parcel.models,
    }),
    ...mapGetters(['currentUser']),
  },
  methods: {
    filter() {
      const params = _.pickBy(this.filterForm, (value) => {
        if (_.isArray(value)) return value.length;
        if (_.isNumber(value)) return ~value;
        return Boolean(value);
      });
      if (_.size(params)) {
        this.$emit('filter', params);
      }
    },
    reset() {
      this.filterForm = {
        status: this.status,
        issue: this.issue,
        printed: this.printed,
        id: '',
        parcel_code: '',
        tracking_no: '',
        reference_id: '',
        post_office_ids: [],
        parcel_model_ids: [],
        date: [],
        returnStatus: '',
        district_id: [],
        user_id: [],
        refund_status: '-1',
        payment_status: '-1',
      };
      this.$emit('filter', {});
    },
  },
};
</script>

<style lang="scss">

  .filter-form {
    .el-form-item__content {
      width: 350px;
      /*width: 100%;*/
    }
    .el-date-editor {
      .el-range-separator {
        width: 8%;
      }
    }

    .el-form-item {
      margin-left: 80px;
    }
  }
</style>
