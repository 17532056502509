<template>
  <div class="order-info-table">
    <table class="table">
      <tbody>
      <tr>
        <th>狀態</th>
        <td>
          {{ orderStatusesObj[order.status] ? orderStatusesObj[order.status].label : '' }}
          <!--<el-button type="danger" class="ml-2" size="small" @click="cancelOrder" v-if="order.status < 4">關閉訂單-->
          <!--</el-button>-->
        </td>
      </tr>
      <tr>
        <th>訂單號</th>
        <td>{{ order.display_id }}</td>
      </tr>
      <tr>
        <th>訂單小票</th>
        <td v-if="order.small_ticket_path">
          <el-button
              type="primary"
              size="mini"
              @click="imgViewerDialog = true;"
          >
            <slot>查看圖片</slot>
            <el-dialog
                :visible.sync="imgViewerDialog"
                width="50%"
                :append-to-body="true"
            >
              <div class="row">
                <div class="col text-center">
                  <el-image :src="order.small_ticket_path"></el-image>
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="imgViewerDialog = false;">關閉</el-button>
              </span>
            </el-dialog>
          </el-button>
        </td>
        <td v-else>無</td>
      </tr>
      <tr>
        <th>運單號</th>
        <td>
          {{ order.tracking_no }}
        </td>
      </tr>
      <tr>
        <th>郵包號</th>
        <td>{{ order.parcel_code }}</td>
      </tr>
      <tr v-if="order.expected_post_date">
        <th>预计投递时间</th>
        <td>{{ order.expected_post_date }}</td>
      </tr>
      <tr v-if="order.version === '1'">
        <th>郵包模型</th>
        <td>
          {{ order.parcel_model.name }}
          (最大承重量：{{ order.parcel_model.max_weight }} kg)
          <el-button type="primary" class="ml-2" size="small" @click="parcelModelDialogVisible = true"
                     v-if="orderEditable">更改郵包模型
          </el-button>
        </td>
      </tr>
      <tr v-if="order.version === '2'">
        <th>尺寸</th>
        <td>
          {{ order.long }} &times; {{ order.width }} &times; {{ order.height }} (CM)
          <el-button type="primary" class="ml-2" size="small" @click="parcelSizeDislogVisible = true"
                     v-if="orderEditable">
            更改郵包規格
          </el-button>
        </td>
      </tr>
      <tr v-if="order.version === '2'">
        <th>申報重量</th>
        <td>{{ order.weight }}KG</td>
      </tr>
      <tr>
        <th>所屬門店</th>
        <td>{{ order.post_office.name }}</td>
      </tr>
      <tr>
        <th>用戶</th>
        <td>
          <div class="user-column">
            <el-avatar size="small" :src="order.user.headimgurl"></el-avatar>
            {{ order.user.nickname }}
          </div>
        </td>
      </tr>
      <template v-if="order.sender_address">
        <tr>
          <th>寄件人姓名</th>
          <td>{{ order.sender_address.name }}</td>
        </tr>
        <tr>
          <th>寄件人電話</th>
          <td>{{ order.sender_address.phone }}</td>
        </tr>
      </template>
      <template v-if="order.receiver_address">
        <tr>
          <th>收件人姓名</th>
          <td>{{ order.receiver_address.name }}</td>
        </tr>
        <tr>
          <th>收件人電話</th>
          <td>{{ order.receiver_address.phone }}
            <el-button type="primary" class="ml-2" size="small" @click="openChangeShipping"
                       v-if="order.status < 4">修改地址信息
            </el-button>
          </td>
        </tr>
        <tr>
          <th>收件人身份證號碼</th>
          <td>
            {{ order.receiver_address.card_no }}
            <br/>
            <id-card-dialog-button :order="order" v-if="order.receiver_address.card_file_url"></id-card-dialog-button>
          </td>
        </tr>
        <tr>
          <th>收件人地址</th>
          <td>
            {{ order.receiver_address.district }}<br/>
            {{ order.receiver_address.address }}
          </td>
        </tr>
      </template>
      <template v-if="order.version === '1'">
        <tr>
          <th>郵包價格</th>
          <td>{{ order.parcel_price | currency }}</td>
        </tr>
        <tr>
          <th>总税金</th>
          <td>{{ order.tax | currency }}</td>
        </tr>
        <tr>
          <th>总價格</th>
          <td>{{ order.price | currency }}</td>
        </tr>
      </template>

      <tr v-if="order.discount_price !== order.price">
        <th>優惠價格</th>
        <td>{{ order.discount_price | currency }}</td>
      </tr>
      <tr>
        <th>下單時間</th>
        <td>{{ order.created_at }}</td>
      </tr>
      </tbody>
    </table>

    <template v-if="order.version === '2'">
      <el-divider/>
      <h4>費用明細</h4>
      <table class="table mt-3">
        <tbody>
          <tr>
            <th>基本運費</th>
            <td>{{ order.price_detail.base | currency }}</td>
          </tr>
          <tr v-if="order.price_detail.peak_season">
            <th>旺季附加费</th>
            <td>{{ order.price_detail.peak_season | currency }}</td>
          </tr>
          <tr v-if="order.price_detail.fuel_surcharge">
            <th>燃油附加费</th>
            <td>{{ order.price_detail.fuel_surcharge | currency }}</td>
          </tr>
          <tr v-if="order.price_detail.disposal">
            <th>排仓费</th>
            <td>{{ order.price_detail.disposal | currency }}</td>
          </tr>
          <tr v-if="order.price_detail.weight_overload">
            <th>超重附加费</th>
            <td>{{ order.price_detail.weight_overload | currency }}</td>
          </tr>
          <tr v-if="order.price_detail.size_overload">
            <th>超长附加费</th>
            <td>{{ order.price_detail.size_overload | currency }}</td>
          </tr>
          <tr v-if="order.price_detail.remote">
            <th>偏远附加费</th>
            <td>{{ order.price_detail.remote | currency }}</td>
          </tr>
          <tr v-if="order.price_detail.residential">
            <th>住宅附加費</th>
            <td>{{ order.price_detail.residential | currency }}</td>
          </tr>
          <tr v-if="order.price_detail.tax">
            <th>稅金</th>
            <td>{{ order.price_detail.tax | currency }}</td>
          </tr>
          <tr>
            <th>總價</th>
            <td :style="{'text-decoration': order.discount_price !== order.price ? 'line-through' : 'none'}">{{ order.price | currency }}</td>
          </tr>
          <tr v-if="order.discount_price !== order.price">
            <th>優惠價</th>
            <td>{{ order.discount_price | currency }}</td>
          </tr>
          <tr v-if="order.coupon">
            <th>優惠碼</th>
            <td>
              <router-link :to="{ name: 'coupon-detail', params: { id: order.coupon.code }}">{{ order.coupon.code }}</router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <el-divider/>
    <h4>附加費</h4>

    <table class="table mt-3 table-bordered">
      <tbody>
      <tr>
        <th>附加費名稱</th>
        <th>原因</th>
        <th>金額</th>
        <th>支付訂單號</th>
        <th>支付狀態</th>
        <th></th>
      </tr>
      <template v-if="order.additional_fees.length">
        <tr v-for="fee in order.additional_fees" :key="fee.id">
          <td>{{ fee.name }}</td>
          <td>{{ fee.reason }}</td>
          <td>{{ fee.price | currency }}</td>
          <td>{{ fee.pay_id }}</td>
          <td>
            <i class="fas fa-check-circle text-success" v-if="fee.payment_status === 1"></i>
            <i class="fas fa-times-circle text-danger" v-else></i>
          </td>
          <td>
            <el-button
                type="danger" size="small"
                :disabled="fee.payment_status === 1"
                v-if="orderEditable"
                @click="cancelAdditionalFee(fee.id)">取消
            </el-button>
          </td>
        </tr>
      </template>
      <tr v-else>
        <td class="text-center" colspan="5">沒有附加費</td>
      </tr>
      <tr>
        <td class="text-center" colspan="5">
          <el-button type="primary" size="small" @click="additionalFeeDialogVisible = true" v-if="orderEditable">
            添加附加費
          </el-button>
          <el-button type="warning" size="small" @click.prevent="recalculatePrice">
            重新計價
          </el-button>
        </td>
      </tr>
      </tbody>
    </table>

    <el-dialog
        :visible.sync="parcelModelDialogVisible"
        title="更改郵包模型"
        :append-to-body="true"
        @open="initChangeParcelModelForm"
    >
      <el-form ref="changeParcelModelForm" :model="changeParcelModelForm" :rules="changeParcelModelFormRules"
               label-width="6rem">
        <el-form-item label="郵包模型" prop="parcel_model_id">
          <el-select v-model="changeParcelModelForm.parcel_model_id" clearable class="w-100" @change="calcDiffPrice">
            <el-option
                v-for="parcel_model in parcelModelsExclude"
                :key="parcel_model.id"
                :label="parcel_model.name"
                :value="parcel_model.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="補差價" prop="additional_fee">
          <el-input-number v-model="changeParcelModelForm.additional_fee" :min="0" :precision="2"/>
          (HKD)
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="parcelModelDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="changeParcelModel">確定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="parcelSizeDislogVisible"
      title="更改郵包規格"
      :append-to-body="true"
      @open="initChangeParcelSizeForm"
    >
      <el-form ref="changeParcelSizeForm" :model="changeParcelSizeForm" :rules="changeParcelSizeFormRules">
        <el-form-item label="類型" prop="parcel_type">
          <el-radio-group v-model="changeParcelSizeForm.parcel_type">
            <el-radio-button label="spx">郵包</el-radio-button>
            <el-radio-button label="doc">文件</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="長度" prop="long">
          <el-input-number v-model="changeParcelSizeForm.long" :precision="1"></el-input-number> CM
        </el-form-item>
        <el-form-item label="寬度" prop="width">
          <el-input-number v-model="changeParcelSizeForm.width" :precision="1"></el-input-number> CM
        </el-form-item>
        <el-form-item label="高度" prop="height">
          <el-input-number v-model="changeParcelSizeForm.height" :precision="1"></el-input-number> CM
        </el-form-item>
        <el-form-item label="重量" prop="weight">
          <el-input-number v-model="changeParcelSizeForm.weight" :precision="2"></el-input-number> KG
        </el-form-item>
        <el-form-item>
          <el-button @click="parcelSizeDislogVisible = false">取消</el-button>
          <el-button type="primary" @click="changeParcelSize">修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
        :visible.sync="additionalFeeDialogVisible"
        title="添加附加費"
        :append-to-body="true"
        @close="initAdditionalFeeForm"
    >
      <el-form ref="additionalFeeForm" :model="additionalFeeForm" :rules="additionalFeeFormRules" label-width="6rem">
        <el-form-item prop="name" label="附加費名稱">
          <el-input v-model="additionalFeeForm.name" placeholder="附加費名稱"/>
        </el-form-item>
        <el-form-item prop="price" label="價格">
          <el-input-number v-model="additionalFeeForm.price" :min="1" :precision="2"/>
        </el-form-item>
        <el-form-item prop="reason" label="原因">
          <el-input v-model="additionalFeeForm.reason" placeholder="收取附加費的原因"/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="additionalFeeDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveAdditionalFee">確定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        :visible.sync="changeShippingDialogVisible"
        title="修改地址信息"
        :append-to-body="true"
    >
      <el-form ref="shippingForm" :model="shippingForm" :rules="shippingFormRules" label-width="10rem">
        <el-form-item prop="sender_name" label="寄件人姓名">
          <el-input v-model="shippingForm.sender_name" placeholder="請輸入寄件人姓名"/>
        </el-form-item>
        <el-form-item label="寄件人電話" prop="sender_phone">
          <el-input v-model.trim="shippingForm.sender_phone" placeholder="請輸入寄件人電話">
            <el-select v-model="shippingForm.sender_phone_area_code" placeholder="手机区号"
                       slot="prepend" style="width: 150px">
              <el-option v-for="item in areaCodes" :key="item.code" :value="item.value"
                         :label="item.name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item label="收件地區" prop="district_id_nodes">
          <el-cascader
              class="w-100"
              placeholder="請選擇所在地區"
              v-model="shippingForm.district_id_nodes"
              :options="districts"
              :props="districtsProps"
              @change="getDistrictId"
              filterable
          ></el-cascader>
        </el-form-item>

        <el-form-item prop="address" label="收件地址">
          <el-input v-model="shippingForm.address" placeholder="請輸入收件地址"/>
        </el-form-item>
        <el-form-item prop="receiver_name" label="收件人姓名">
          <el-input v-model="shippingForm.receiver_name" placeholder="請輸入收件人姓名"/>
        </el-form-item>
        <el-form-item label="收件人電話" prop="receiver_phone">
          <el-input v-model.trim="shippingForm.receiver_phone" placeholder="請輸入收件人電話">
            <el-select v-model="shippingForm.receiver_phone_area_code" placeholder="手机区号"
                       slot="prepend" style="width: 150px">
              <el-option v-for="item in areaCodes" :key="item.code" :value="item.value"
                         :label="item.name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <!--<el-form-item prop="receiver_card_no" label="收件人身份證號碼">-->
          <!--<el-input v-model="shippingForm.receiver_card_no" placeholder="請輸入收件人身份證號碼"/>-->
        <!--</el-form-item>-->

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeShippingDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveShipping">確定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import IdCardDialogButton from '@/components/Order/IdCardDialogButton';
import { getLoadingInstance, showErrorMessage, formatMoney } from '@/helpers';

export default {
  name: 'OrderInfoTable',
  props: {
    order: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      imgViewerDialog: false,
      districts: [],
      districtsProps: {
        value: 'id',
        label: 'name',
        children: 'children',
      },
      areaCodes: [
        {
          name: '香港 +852',
          value: '+852',
        },
        {
          name: '中國大陸 +86',
          value: '+86',
        },
      ],
      changeShippingDialogVisible: false,
      parcelModelDialogVisible: false,
      additionalFeeDialogVisible: false,
      parcelSizeDislogVisible: false,
      changeParcelModelForm: {
        parcel_model_id: null,
        additional_fee: 0,
      },
      changeParcelModelFormRules: {
        parcel_model_id: {
          required: true,
          message: '請選擇郵包模型',
          trigger: 'change',
        },
      },
      changeParcelSizeForm: {
        long: 0,
        width: 0,
        height: 0,
        weight: 0,
        parcel_type: '',
      },
      changeParcelSizeFormRules: {
        long: [
          { required: true, message: '請輸入長度', trigger: 'change' },
          {
            type: 'number',
            min: 1,
            trigger: 'change',
            message: '長度最小為1',
          },
        ],
        width: [
          { required: true, message: '請輸入寬度', trigger: 'change' },
          {
            type: 'number',
            min: 1,
            trigger: 'change',
            message: '寬度最小為1',
          },
        ],
        height: [
          { required: true, message: '請輸入高度', trigger: 'change' },
          {
            type: 'number',
            min: 1,
            trigger: 'change',
            message: '高度最小為1',
          },
        ],
        weight: [
          { required: true, message: '請輸入重量', trigger: 'change' },
          {
            type: 'number',
            min: 1,
            trigger: 'change',
            message: '重量最小為1',
          },
        ],
      },
      additionalFeeForm: {
        name: '',
        price: 1,
        reason: '',
      },
      additionalFeeFormRules: {
        name: {
          required: true,
          message: '請輸入附加費名稱',
          trigger: 'blur',
        },
        price: [
          {
            required: true,
            message: '請輸入附加費金額',
            trigger: 'change',
          },
          {
            type: 'number',
            min: 1,
            message: '附加費金額不正確',
            trigger: 'change',
          },
        ],
        reason: {
          required: true,
          message: '請輸入收取附加費的原因',
          trigger: 'blur',
        },
      },
      shippingForm: {
        sender_name: '',
        sender_phone_area_code: '',
        sender_phone: '',
        receiver_name: '',
        receiver_phone_area_code: '',
        receiver_phone: '',
        receiver_card_no: '',
        district_id: '',
        district_id_nodes: '',
      },
      shippingFormRules: {
        sender_name: {
          required: true,
          message: '請輸入寄件人姓名',
          trigger: 'blur',
        },
        sender_phone: {
          required: true,
          message: '請輸入寄件人電話',
          trigger: 'blur',
        },
        receiver_name: {
          required: true,
          message: '請輸入收件人姓名',
          trigger: 'blur',
        },
        receiver_phone: {
          required: true,
          message: '請輸入收件人電話',
          trigger: 'blur',
        },
        // receiver_card_no: {
        //   required: true,
        //   message: '請輸入收件人身份證號碼',
        //   trigger: 'blur',
        // },
        address: {
          required: true,
          message: '請輸入收件地址',
          trigger: 'blur',
        },
        district_id_nodes: {
          required: true,
          message: '请输入所在地區',
          trigger: 'change',
        },
      },
    };
  },
  mounted() {
    this.$api.district.tree()
      .then(({ data: response }) => {
        this.districts = response;
      });
  },
  computed: {
    ...mapGetters(['orderStatusesObj']),
    ...mapState({
      parcelModels: state => state.parcel.models,
    }),
    parcelModelsExclude() {
      return _.reject(this.parcelModels, item => item.id === this.order.parcel_model.id);
    },
    orderEditable() {
      return true;
      // return this.order.status === 1 || this.order.status === 2 || this.order.status === 3;
    },
  },
  methods: {
    closeIdCardDialog() {
      this.imgViewerDialog = false;
    },
    getDistrictId() {
      if (this.shippingForm.district_id_nodes.length) {
        const index = this.shippingForm.district_id_nodes.length - 1;
        this.shippingForm.district_id = this.shippingForm.district_id_nodes[index];
      }
    },
    initChangeParcelModelForm() {
      this.changeParcelModelForm = {
        parcel_model_id: null,
        additional_fee: 0,
      };
    },
    initAdditionalFeeForm() {
      this.additionalFeeForm = {
        name: '',
        price: 1,
        reason: '',
      };
    },
    initChangeParcelSizeForm() {
      this.changeParcelSizeForm = {
        long: this.order.long,
        width: this.order.width,
        height: this.order.height,
        weight: this.order.weight,
        parcel_type: this.order.parcel_type,
      };
    },
    openChangeShipping() {
      this.changeShippingDialogVisible = true;
      this.shippingForm = {
        sender_name: this.order.sender_address.name,
        sender_phone_area_code: this.order.sender_address.phone.split(' ')[0],
        sender_phone: this.order.sender_address.phone.split(' ')[1],
        receiver_name: this.order.receiver_address.name,
        district_id: this.order.receiver_address.district_id,
        district_id_nodes: this.order.receiver_address.district_id_nodes,
        address: this.order.receiver_address.address,
        receiver_card_no: this.order.receiver_address.card_no,
        receiver_phone_area_code: this.order.receiver_address.phone.split(' ')[0],
        receiver_phone: this.order.receiver_address.phone.split(' ')[1],
      };
    },
    calcDiffPrice(id) {
      const parcelModel = _.find(this.parcelModels, { id });
      const diffPrice = parcelModel.price - this.order.parcel_model.price;
      this.changeParcelModelForm.additional_fee = diffPrice > 0 ? diffPrice : 0;
    },
    changeParcelModel() {
      this.$refs.changeParcelModelForm.validate((valid) => {
        if (valid) {
          const params = { action: 'change_parcel_model', ...this.changeParcelModelForm };
          const loading = getLoadingInstance();
          this.$api.order.update(this.order.id, params)
            .then(({ data: response }) => {
              this.$emit('updateOrder', response.data);
              this.$notify.success({
                message: '已更換郵包模型',
              });
            })
            .catch((e) => {
              showErrorMessage(e);
            })
            .finally(() => {
              loading.close();
              this.parcelModelDialogVisible = false;
            });
        }
      });
    },
    changeParcelSize() {
      this.$refs.changeParcelSizeForm.validate((valid) => {
        if (valid) {
          const params = { action: 'change_parcel_size', ...this.changeParcelSizeForm };
          const loading = getLoadingInstance();
          this.$api.order.update(this.order.id, params)
            .then(({ data: response }) => {
              this.$emit('updateOrder', response.data);
              this.$notify.success({
                message: '已更新郵包規格',
              });
            })
            .catch((e) => {
              showErrorMessage(e);
            })
            .finally(() => {
              loading.close();
              this.parcelSizeDislogVisible = false;
            });
        }
      });
    },
    saveShipping() {
      this.$refs.shippingForm.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          this.$api.order.update(this.order.id, {
            ...this.shippingForm,
            action: 'change_shipping',
          })
            .then(({ data: response }) => {
              this.$emit('updateOrder', response.data);
              this.$notify.success({
                message: '修改成功',
              });
            })
            .catch((e) => {
              showErrorMessage(e);
            })
            .finally(() => {
              loading.close();
              this.changeShippingDialogVisible = false;
            });
        }
      });
    },
    saveAdditionalFee() {
      this.$refs.additionalFeeForm.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          const params = { order_id: this.order.id, ...this.additionalFeeForm };
          this.$api.orderAdditionalFee.save(params)
            .then(({ data: response }) => {
              this.$emit('updateOrder', response.data);
              this.$notify.success({
                message: '已添加附加費',
              });
            })
            .catch((e) => {
              showErrorMessage(e);
            })
            .finally(() => {
              loading.close();
              this.additionalFeeDialogVisible = false;
            });
        }
      });
    },
    cancelAdditionalFee(id) {
      this.$confirm('確定要刪除這條附加費嗎？', '提示', {
        type: 'warning',
      })
        .then(() => {
          const loading = getLoadingInstance();
          this.$api.orderAdditionalFee.cancel(id)
            .then(() => {
              this.$emit('cancelAdditionalFee', id);
              this.$notify.success({
                message: '已刪除附加費',
              });
            })
            .catch((e) => {
              showErrorMessage(e);
            })
            .finally(() => {
              loading.close();
            });
        });
    },
    recalculatePrice() {
      this.$api.order.recalculatePrice(this.order.id).then(({ data: response }) => {
        if (response.total === this.order.price) {
          this.$alert('價格無差異', '提示');
        } else {
          this.$confirm(`
            当前價格：${formatMoney(this.order.price, 'HKD')}
            <hr />
            重新價格後：<br />
            基本運費：${formatMoney(response.base, 'HKD')}<br />
            旺季附加费：${formatMoney(response.peak_season, 'HKD')}<br />
            排仓费：${formatMoney(response.disposal, 'HKD')}<br />
            超重附加费：${formatMoney(response.weight_overload, 'HKD')}<br />
            超长附加费：${formatMoney(response.size_overload, 'HKD')}<br />
            偏远附加费：${formatMoney(response.remote, 'HKD')}<br />
            住宅附加費：${formatMoney(response.residential, 'HKD')}<br />
            稅金：${formatMoney(response.tax, 'HKD')}<br />
            <span class="text-info">優惠金額：-${formatMoney(response.discount, 'HKD')}</span><br />
            <span class="text-primary">總價：${formatMoney(response.total, 'HKD')}</span><br />
            <span class="text-danger">需要補差價:${formatMoney(response.diffPrice, 'HKD')}</span>
          `, '是否確定重新計價？', {
            dangerouslyUseHTMLString: true,
          }).then(() => {
            this.additionalFeeDialogVisible = true;
            this.additionalFeeForm = {
              name: '補差價',
              price: response.diffPrice,
              reason: '補差價',
            };
          });
        }
      });
    },
    cancelOrder() {
      this.$confirm('確定要關閉訂單嗎？', '提示', {
        type: 'warning',
      })
        .then(() => {
          const loading = getLoadingInstance();
          this.$api.order.close(this.order.id)
            .then(({ data: response }) => {
              this.$emit('updateOrder', response.data);
              this.$notify.success({
                message: '訂單已關閉',
              });
            })
            .catch((e) => {
              showErrorMessage(e);
            })
            .finally(() => {
              loading.close();
            });
        });
    },
  },
  components: {
    IdCardDialogButton,
  },
};
</script>

<style lang="scss" scoped>
  .user-column {
    display: flex;
    align-items: center;

    span {
      margin-right: 5px;
    }
  }
</style>
