<template>
  <section>
    <PickupOrderFilter ref="orderFilter" @returnOrders="receiveOrders" :filterStatus="true"></PickupOrderFilter>

    <div class="card card-default">
      <div class="card-body">
        <pickup-order-table
          ref="orderTable"
          :data="orders"
          :columns="tableConfigs.columns"
          :options="tableConfigs.options"
          selectable
        ></pickup-order-table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import PickupOrderFilter from '@/components/PickupOrder/OrderFilter';
import PickupOrderTable from '@/components/PickupOrder/OrderTable';

export default {
  name: 'OrderAll',
  data() {
    return {
      orders: [],
      tableConfigs: {
        columns: [
          'id', 'price', 'user', 'status', 'user_time', 'remark', 'created_at', 'operation',
        ],
        options: {
          sortable: ['parcel_code', 'parcel_model', 'tracking_no'],
        },
      },
    };
  },
  computed: {
    ...mapGetters(['pickupOrderTabsObj']),
  },
  watch: {
    'pickupOrderTabsObj.all.update': {
      handler(value) {
        if (value) {
          this.getOrder();
        }
      },
      immediate: true,
    },
  },
  methods: {
    receiveOrders(orders) {
      this.orders = orders;
    },
    getOrder() {
      this.$refs.orderFilter.fetchOrder().then(() => {
        this.$store.commit('setPickupOrderTabUpdate', { name: 'all', update: false });
      });
    },
  },
  components: {
    PickupOrderFilter,
    PickupOrderTable,
  },
};
</script>
