<template>
  <section>
    <div class="operators">
    <el-checkbox
      border
      :indeterminate="isIndeterminate"
      v-model="checkAll"
      @change="handleCheckAllChange"
      v-if="exportable || selectable"
    >全选</el-checkbox>

      <el-button type="primary" class="ml-2" @click="showAllData = !showAllData">
        <span v-if="showAllData">顯示分頁</span>
        <span v-else>顯示全部數據</span>
      </el-button>
      <el-button type="primary" class="ml-2" :disabled="!checked.length" @click="exportData" v-if="exportable">
        導出數據
      </el-button>
      <el-button type="primary" class="ml-2" :disabled="!checked.length" @click="batchIssue" v-if="handleIssue">
        批量移到問題訂單
      </el-button>
      <el-button type="primary" class="ml-2" :disabled="!checked.length" @click="batchSolution" v-if="removeIssue">
        批量移出問題訂單
      </el-button>
      <router-link class="ml-2 el-button el-button--primary" style="text-decoration: none;"  :to="{ name: 'order-issues' }"
                   v-if="removeIssue">
        全部問題訂單記錄
      </router-link>
    </div>

    <v-server-table ref="table" class="mt-2" :data="datas" :columns="tableConfigs.columns" :options="tableConfigs.options" @loaded="onTableLoaded">

      <template v-slot:child_row="props">
        <el-card>
          <div class="row">
            <div class="col" v-if="props.row.sender_address">
              <h5>寄件人</h5>
              <table class="table">
                <tbody>
                  <tr>
                    <th>姓名</th>
                    <td>{{ props.row.sender_address.name }}</td>
                  </tr>
                  <tr>
                    <th>電話</th>
                    <td>{{ props.row.sender_address.address }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col" v-if="props.row.receiver_address">
              <h5>收件人</h5>
              <table class="table">
                <tbody>
                  <tr>
                    <th>姓名</th>
                    <td>{{ props.row.receiver_address.name }}</td>
                  </tr>
                  <tr>
                    <th>電話</th>
                    <td>{{ props.row.receiver_address.address }}</td>
                  </tr>
                  <tr>
                    <th>地址</th>
                    <td>
                      {{ props.row.receiver_address.district }}<br />
                      {{ props.row.receiver_address.address }}
                    </td>
                  </tr>
                  <tr>
                    <th>身份證號碼</th>
                    <td>
                      {{ props.row.receiver_address.card_no }}
                      <br />
                      <id-card-dialog-button :order="props.row"></id-card-dialog-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-card>

        <el-card class="mt-3">
          <div slot="header" class="clearfix">商品信息</div>
          <table class="table table-striped">
            <tbody>
            <tr>
              <th>商品名稱</th>
              <th>數量</th>
              <th>價格</th>
              <th>淨重</th>
              <th>毛重</th>
            </tr>
            <tr v-for="item in props.row.order_items" :key="item.id">
              <td>{{ item.product_name }}</td>
              <td>{{ item.product_count }}</td>
              <td>{{ item.currency }}{{ item.price }}</td>
              <td>{{ item.net_weight }}</td>
              <td>{{ item.gross_weight }}</td>
            </tr>
            </tbody>
          </table>
        </el-card>
        <el-card class="mt-3">
          <div slot="header" class="clearfix">附加費信息</div>
          <table class="table table-striped">
            <tbody>
            <tr>
              <th>附加費名稱</th>
              <th>原因</th>
              <th>金額</th>
              <th>支付訂單號</th>
              <th>支付狀態</th>
            </tr>
            <template v-if="props.row.additional_fees.length">
              <tr v-for="fee in props.row.additional_fees" :key="fee.id">
                <td>{{ fee.name }}</td>
                <td>{{ fee.reason }}</td>
                <td>{{ fee.price | currency }}</td>
                <td>{{ fee.pay_id }}</td>
                <td>
                  <i class="fas fa-check-circle text-success" v-if="fee.payment_status === 1"></i>
                  <i class="fas fa-times-circle text-danger" v-else></i>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td class="text-center" colspan="5">沒有附加費</td>
            </tr>
            </tbody>
          </table>
        </el-card>
      </template>

      <template slot="user" slot-scope="props">
        <div class="flex-center">
          <el-avatar size="small" :src="props.row.user.headimgurl"></el-avatar>
          {{ props.row.user.nickname }}
        </div>
      </template>

      <template slot="price" slot-scope="props">
        <span v-if="props.row.discount_price !== props.row.price">
          <span class="text-danger d-block">優惠價：{{ props.row.discount_price + props.row.additional_total | currency }}</span>
          <span class="text d-block">原價：{{ props.row.price + props.row.additional_total | currency }}</span>
        </span>
        <span v-else>{{ props.row.discount_price + props.row.additional_total | currency }}</span>
      </template>

      <template slot="total_weight" slot-scope="props">
        {{ props.row.total_weight ? props.row.total_weight + 'KG' : '未稱重' }}
      </template>

      <template slot="selectable" slot-scope="props">
        <el-checkbox v-model="checked" :label="props.row.id" @change="handleCheckedChange">
          <span></span>
        </el-checkbox>
      </template>

      <template slot="parcel_model" slot-scope="props">

        <template v-if="props.row.issue">
          <el-tooltip placement="top" :content="props.row.issue.question">
            <i class="fa fa fa-exclamation-triangle" aria-hidden="true"></i>
          </el-tooltip>
        </template>
        {{ props.row.parcel_model ? props.row.parcel_model.name : '' }}
      </template>

      <template slot="post_office" slot-scope="props">
        {{ props.row.post_office ? props.row.post_office.name : '' }}
      </template>

      <template slot="duty_paid" slot-scope="props">
        {{ props.row.duty_paid ? 'Y' : 'N' }}
      </template>

      <template slot="sync" slot-scope="props">
        {{ props.row.sync ? 'Y' : 'N' }}
      </template>

      <template slot="status" slot-scope="props">
        {{ orderStatusesObj[props.row.status] ? orderStatusesObj[props.row.status].label : '' }}
      </template>

      <template slot="operation" slot-scope="props">
        <el-popconfirm title="確認完成訂單嗎？" @confirm="completeOrder(props.row.id)">
          <el-button
              size="small"
              slot="reference"
              type="success"
              class="operator-button"
              v-if="props.row.status === 4"
          >
            完成訂單
          </el-button>
        </el-popconfirm>
        <el-popconfirm title="確定要關閉這個訂單？" @confirm="closeOrder(props.row.id)">
          <el-button
              size="small"
              type="danger"
              slot="reference"
              class="operator-button"
              v-if="props.row.status < 5"
          >
            取消訂單（退款）
          </el-button>
        </el-popconfirm>
        <el-popconfirm title="確定要關閉這個訂單？" @confirm="closeOrder(props.row.id, true)">
          <el-button
              size="small"
              type="danger"
              slot="reference"
              class="operator-button"
              v-if="props.row.status < 5"
          >
            關閉訂單（不退款）
          </el-button>
        </el-popconfirm>
        <router-link class="el-button operator-button el-button--primary el-button--small text-decoration-none"
                     target="_blank" :to="{ name: 'order-detail', params: { id: props.row.id }}">詳細</router-link>
        <!--<el-button type="primary" size="small" class="operator-button"-->
                   <!--@click="$router.push({ name: 'order-detail', params: { id: props.row.id }})">詳細</el-button>-->
        <el-button
          size="small"
          class="operator-button"
          @click="$router.push({ name: 'weighting-print-label', params: { parcel_code: props.row.parcel_code }})"
          v-if="props.row.status === 3"
        >稱重打單</el-button>

        <el-popconfirm title="確認已經攬件嗎？" @confirm="orderPicked(props.row.id)">
          <el-button
              size="small"
              slot="reference"
              class="operator-button"
              v-if="props.row.status === 2"
          >
            確認攬件
          </el-button>
        </el-popconfirm>
        <el-popconfirm title="確認將訂單改為港前退件嗎？" @confirm="orderReturn(props.row.id, 'before')">
          <el-button
              size="small"
              slot="reference"
              class="operator-button"
              v-if="props.row.status === 4"
          >
            港前退件
          </el-button>
        </el-popconfirm>
        <el-popconfirm title="確認將訂單改為港後退件嗎？" @confirm="orderReturn(props.row.id, 'after')">
          <el-button
              size="small"
              slot="reference"
              class="operator-button"
              v-if="props.row.status === 4"
          >
            港後退件
          </el-button>
        </el-popconfirm>
        <el-button class="operator-button" size="small" @click="printLabel(props.row.id)" v-if="props.row.status === 4">打印Label</el-button>
        <el-button class="operator-button" size="small" @click="printInvoice(props.row.id)">打印Invoice</el-button>
        <el-button class="operator-button" size="small" @click="resetPrint(props.row.id)" v-if="props.row.label_prints > 0">重置打印次數</el-button>
        <el-button class="operator-button" size="small" @click="tracking(props.row.id)" v-if="props.row.tracking_no">跟蹤訂單</el-button>
        <el-button class="operator-button" size="small"
                   @click="changeTracking(props.row.id)" v-if="props.row.status === 3 || props.row.status === 4">
          修改物流
        </el-button>
        <el-button class="operator-button" size="small" @click="orderIssue(props.row)" v-if="handleIssue">移到問題訂單</el-button>
        <el-button type="danger" class="operator-button" size="small" @click="orderSolution(props.row)" v-if="props.row.issue">移出問題訂單</el-button>
        <el-button
          size="small" class="operator-button"
          @click="$router.push({ name: 'order-split', params: { parcel_code: props.row.parcel_code }})"
          v-if="removeIssue"
        >訂單拆分</el-button>
        <el-button size="small"
                   class="operator-button"
                   @click="showIssue(props.row)"
                   v-if="removeIssue">查看問題</el-button>
      </template>

    </v-server-table>

    <el-dialog
      :title="trackingDialog.title"
      :visible.sync="trackingDialog.visible"
      width="50%"
      :append-to-body="true"
    >
      <el-timeline placement="top" v-if="trackings">
        <el-timeline-item
          v-for="(item, key) in trackings"
          :key="item.status + key"
          :timestamp="item.time"
        >
          <el-card>
            <span class="text-danger">{{ item.city }}</span> {{ item.message }}
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <span v-else>暫無物流信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="trackingDialog.visible = false">關閉</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="問題訂單"
        :visible.sync="orderIssueDialogVisible"
        width="70%"
        :append-to-body="true"
        :show-close="false"
        :close-on-click-modal="false"
    >
      <el-form label-width="7rem" v-if="currentOrders">

        <el-table
            ref="multipleTable"
            :data="currentOrders"
            tooltip-effect="dark"
            style="width: 100%">
          <el-table-column
              prop="id"
              label="訂單號"
              width="200"
              show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              prop="id"
              label="郵包模型"
              width="200"
              show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.parcel_model ? scope.row.parcel_model.name : ''}}
            </template>
          </el-table-column>
          <el-table-column
              prop="user_name"
              label="用戶"
              show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="flex-center">
                <el-avatar size="small" :src="scope.row.user.headimgurl"></el-avatar>
                {{ scope.row.user.nickname }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="總價"
              width="120">
            <div style="white-space: pre-wrap" slot-scope="scope">
              {{ scope.row.price !== scope.row.discount_price ?
              `原價：${scope.row.price} \n 優惠價：${scope.row.discount_price}` :
              scope.row.price }}
            </div>
          </el-table-column>
          <el-table-column
              prop="status_str"
              label="訂單狀態"
              show-overflow-tooltip>
            <template slot-scope="props">
              {{ orderStatusesObj[props.row.status] ? orderStatusesObj[props.row.status].label : '' }}</template>
          </el-table-column>
          <el-table-column
              v-if="issueType === 2"
              prop="issue.question"
              label="當前訂單問題"
              show-overflow-tooltip>
          </el-table-column>
        </el-table>

        <el-form-item label="訂單問題" class="mt-4" v-if="issueType === 1">
          <el-input v-model="issue" placeholder="輸入訂單問題"></el-input>
        </el-form-item>

        <el-form-item label="解決方法" class="mt-4" v-if="issueType === 2">
          <el-input v-model="solution" placeholder="輸入解決方法"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="orderIssueDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="changeIssue()">確定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="修改物流"
        :visible.sync="changeTrackingDialogVisible"
        width="70%"
        :append-to-body="true"
        :show-close="false"
        :close-on-click-modal="false"
    >
      <el-form label-width="7rem">

        <el-form-item label="訂單ID" class="mt-4" >
          <el-input v-model="trackingForm.order_id"  disabled></el-input>
        </el-form-item>
        <el-form-item label="郵包號" class="mt-4">
          <el-input v-model="trackingForm.parcel_code" disabled></el-input>
        </el-form-item>

        <el-form-item label="運單號" class="mt-4">
          <el-input v-model="trackingForm.tracking_no" placeholder="新運單號"></el-input>
        </el-form-item>

        <el-form-item label="運輸渠道" prop="ship_way">
          <el-radio-group v-model="trackingForm.ship_way">
            <el-radio-button
                v-for="ship_way in shipWays"
                :key="ship_way.value"
                :label="ship_way.value"
            >
              {{ ship_way.label }}
            </el-radio-button>
            <!--<el-radio-button label="ETK"></el-radio-button>-->
            <!--<el-radio-button label="ETK-AM"></el-radio-button>-->
            <!--<el-radio-button label="ETK-AM"></el-radio-button>-->
          </el-radio-group>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeTrackingDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="postTracking()">確定</el-button>
      </span>
    </el-dialog>

    <div id="invoiceHtml" v-html="invoiceHtml">
    </div>

  </section>

</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import printJS from 'print-js';
import download from 'downloadjs';
import { getLoadingInstance, showErrorMessage } from '@/helpers';
import IdCardDialogButton from '@/components/Order/IdCardDialogButton';
import appConfig from '@/app.config';

export default {
  name: 'OrderTable',
  props: {
    columns: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: '',
    },
    filterParams: {
      type: Object,
      default: () => {},
    },
    selectable: Boolean,
    exportable: Boolean,
    handleIssue: Boolean,
    removeIssue: Boolean,
  },
  data() {
    return {
      invoiceHtml: null,
      showAllData: false,
      changeTrackingDialogVisible: false,
      trackingForm: {
        order_id: null,
        parcel_code: null,
        tracking_no: '',
        ship_way: 'ETK',
      },
      shipWays: [],
      datas: [],
      checked: [],
      checkAll: false,
      isIndeterminate: true,
      trackingDialog: {
        order_id: null,
        title: '',
        visible: false,
      },
      trackings: {},
      orderIssueDialogVisible: false,
      currentOrders: [],
      issue: '',
      solution: '',
      issueType: 1,
    };
  },
  watch: {
    showAllData() {
      this.refresh();
    },
  },
  computed: {
    ...mapGetters([
      'orderStatusesObj',
    ]),
    tableConfigs() {
      let columns;
      if (this.selectable || this.exportable) {
        columns = ['selectable', ...this.columns];
      } else {
        columns = [...this.columns];
      }
      columns.splice(-2, 0, 'pay_type');
      return {
        columns,
        options: {
          requestFunction: this.fetchOrders,
          responseAdapter({ data: response }) {
            return {
              data: response.data,
              count: response.meta.pagination.total,
            };
          },
          headings: {
            selectable: '',
            id: '訂單ID',
            parcel_code: '郵包號',
            parcel_model: '郵包模型',
            post_office: '所屬門店',
            price: '價格',
            tracking_no: '運單號',
            user: '用戶',
            total_weight: '總重量',
            sync: '是否同步',
            label_prints: '打印次數',
            pay_type: '支付工具',
            status: '狀態',
            created_at: '創建時間',
            cancel_time: '關閉時間',
            duty_paid: '預付稅金',
            operation: '操作',
          },
          filterable: false,
          perPage: this.showAllData ? 9999999 : 10,
          ...this.options,
        },
      };
    },
  },
  methods: {
    ...mapMutations(['setOrderTabUpdate']),
    fetchOrders(query) {
      const params = {
        page: query.page,
        perpage: this.showAllData ? 9999999 : query.limit,
        name: this.name,
      };
      if (this.name === 'return' && this.returnStatus) {
        params.return_status = this.returnStatus;
      }
      const loading = getLoadingInstance();
      return this.$api.order.get({ ...params, ...this.filterParams })
        .catch(errors => showErrorMessage(errors))
        .finally(() => {
          loading.close();
        });
    },
    refresh() {
      this.$nextTick(() => {
        this.$refs.table.refresh();
      });
    },
    onTableLoaded({ data }) {
      this.datas = data.data;
    },
    batchSolution() {
      this.issueType = 2;
      this.solution = '';
      this.currentOrders = [];
      _(this.checked).forEach((value) => {
        const order = _.find(this.datas, { id: value });
        this.currentOrders.push(order);
      });
      this.orderIssueDialogVisible = true;
    },
    orderSolution(order) {
      this.issueType = 2;
      this.currentOrders = [];
      if (order) {
        this.currentOrders.push(order);
        this.solution = '';
        this.orderIssueDialogVisible = true;
      }
    },
    showIssue(order) {
      const content = `<span style="color: red;">${order.issue.question_admin}</span>  ： ${order.issue.question}`;
      this.$alert(content, '問題', {
        showConfirmButton: false,
        closeOnClickModal: true,
        dangerouslyUseHTMLString: true,
      });
    },
    changeIssue() {
      const loading = getLoadingInstance();
      let params = {};
      if (this.issueType === 1) {
        params = { ids: _.map(this.currentOrders, 'id'), issue: this.issue };
      } else {
        params = { ids: _.map(this.currentOrders, order => order.issue.issue_id), solution: this.solution };
      }
      this.$api.order.changeIssue(params).then(({ data: response }) => {
        // this.datas = _.reject(this.datas, order => ~_.map(response, 'id').indexOf(order.id));
        this.$notify.success({
          title: `${response.length}個訂單操作成功`,
        });
        this.checked = [];
        this.checkAll = false;
        this.isIndeterminate = false;

        // 移入问题订单
        if (this.issueType === 1) {
          this.setOrderTabUpdate({ name: 'issue', update: true });
          this.$parent.getOrder();
        } else {
          const handleTabs = [];
          response.forEach((order) => {
            let name;
            switch (order.status) {
              case 1:
                name = 'unpaid';
                break;
              case 2:
                name = 'paid';
                break;
              case 3:
                name = 'pickup';
                break;
              case 4:
                if (order.label_prints > 0) {
                  name = 'printed';
                } else {
                  name = 'unprinted';
                }
                break;
              case 11:
                name = 'closed';
                break;
              case 5:
                name = 'completed';
                break;
              case 12:
                name = 'canceled';
                break;
              case 18:
              case 19:
                name = 'return';
                break;
              default:
                name = 'all';
                break;
            }
            handleTabs.push(name);
          });
          _.union(handleTabs).forEach((name) => {
            this.setOrderTabUpdate({ name, update: true });
          });
          this.setOrderTabUpdate({ name: 'all', update: true });
        }
      }).catch(error => showErrorMessage(error)).finally(() => {
        this.orderIssueDialogVisible = false;
        loading.close();
      });
    },
    batchIssue() {
      this.issueType = 1;
      this.issue = '';
      this.currentOrders = [];
      _(this.checked).forEach((value) => {
        const order = _.find(this.datas, { id: value });
        this.currentOrders.push(order);
      });
      this.orderIssueDialogVisible = true;
    },
    orderIssue(order) {
      this.issueType = 1;
      this.currentOrders = [];
      if (order) {
        this.currentOrders.push(order);
        this.issue = '';
        this.orderIssueDialogVisible = true;
      }
    },
    exportData() {
      const loading = getLoadingInstance();
      const { API_HOST } = appConfig;
      const formData = new FormData();
      _.forEach(this.checked, id => formData.append('ids[]', id));
      const url = `${API_HOST}/order-export`;
      const x = new XMLHttpRequest();
      x.open('POST', url, true);
      x.responseType = 'blob';
      x.onload = function (e) {
        loading.close();
        download(e.target.response, '訂單列表.xls', e.target.response.type);
      };
      x.send(formData);
    },
    handleCheckAllChange(val) {
      this.checked = val ? _.map(this.datas, 'id') : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange() {
      const checkedCount = this.checked.length;
      this.checkAll = checkedCount === this.datas.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.datas.length;
    },
    closeOrder(id, force = false) {
      if (force) {
        const loading = getLoadingInstance();
        this.$api.order.forceClose(id).then(() => {
          this.refresh();
          // this.$set(this.datas, _.reject(this.datas, { id }));
          // this.$forceUpdate();
          this.setOrderTabUpdate({ name: 'closed', update: true });
          this.$notify.success({
            title: '操作成功',
          });
        }).catch(error => showErrorMessage(error)).finally(() => {
          this.orderIssueDialogVisible = false;
          loading.close();
        });
      } else {
        const loading = getLoadingInstance();
        this.$api.order.close(id).then(() => {
          this.refresh();
          // this.$set(this.datas, _.reject(this.datas, { id }));
          // this.$forceUpdate();
          this.setOrderTabUpdate({ name: 'closed', update: true });
          this.$notify.success({
            title: '操作成功',
          });
        }).catch(error => showErrorMessage(error)).finally(() => {
          this.orderIssueDialogVisible = false;
          loading.close();
        });
      }
    },
    completeOrder(id) {
      const loading = getLoadingInstance();
      this.$api.order.complete(id).then(() => {
        this.refresh();
        // this.datas = _.reject(this.datas, { id });
        this.setOrderTabUpdate({ name: 'completed', update: true });
        this.$notify.success({
          title: '操作成功',
        });
      }).catch(error => showErrorMessage(error)).finally(() => {
        this.orderIssueDialogVisible = false;
        loading.close();
      });
    },
    printLabel(id) {
      const order = _.find(this.datas, { id });
      if (order) {
        this.$api.order.update(id, { action: 'print_label' }).then(() => {
          // 未打印列表要reject，并刷新已打印列表
          if (!order.label_prints) {
            this.refresh();
            // this.datas = _.reject(this.datas, { id });
            this.setOrderTabUpdate({ name: 'printed', update: true });
          }
          order.label_prints++;
          printJS({
            printable: order.label_url,
            type: 'pdf',
            showModal: true,
          });
        });
      }
    },
    printInvoice(id) {
      const order = _.find(this.datas, { id });
      if (order) {
        this.$api.order.update(id, { action: 'print_invoice' }).then(({ data: response }) => {
          // this.invoiceHtml = response;
          printJS({
            printable: response,
            // printable: 'Invoice',
            type: 'raw-html',
            showModal: true,
          });
        });
      }
    },
    resetPrint(id) {
      const order = _.find(this.datas, { id });
      if (order) {
        this.$api.order.update(id, { label_prints: 0 }).then(() => {
          // reject并刷新未打印列表
          this.refresh();
          // this.datas = _.reject(this.datas, { id });
          this.setOrderTabUpdate({ name: 'unprinted', update: true });
          this.$notify.success({
            title: '該訂單已重置打印次數',
          });
        });
      }
    },
    orderPicked(id) {
      const loading = getLoadingInstance();
      const order = _.find(this.datas, { id });
      if (order) {
        this.$api.order.pickup(order.parcel_code).then(() => {
          // reject并刷新已攬件列表
          this.refresh();
          // this.datas = _.reject(this.datas, { id });
          this.setOrderTabUpdate({ name: 'pickup', update: true });
          this.$notify.success({
            title: '操作成功',
          });
        }).finally(() => {
          loading.close();
        });
      }
    },
    orderReturn(id, status) {
      const loading = getLoadingInstance();
      const order = _.find(this.datas, { id });
      if (order) {
        this.$api.order.return({ id, status }).then(() => {
          // reject并刷新已攬件列表
          this.refresh();
          // this.datas = _.reject(this.datas, { id });
          if (status === 'after') {
            // 港前
            this.setOrderTabUpdate({ name: 'return', update: true });
          }
          if (status === 'before') {
            // 港後
            this.setOrderTabUpdate({ name: 'return', update: true });
          }
          this.$notify.success({
            title: '操作成功',
          });
        }).finally(() => {
          loading.close();
        });
      }
    },
    changeTracking(id) {
      const order = _.find(this.datas, { id });
      this.trackingForm.tracking_no = order.tracking_no;
      this.trackingForm.ship_way = order.ship_way;
      this.trackingForm.parcel_code = order.parcel_code;
      this.shipWays = order.ship_ways;
      this.trackingForm.order_id = id;
      this.changeTrackingDialogVisible = true;
    },
    postTracking() {
      const loading = getLoadingInstance();
      const params = {
        action: 'change_tracking',
        ship_way: this.trackingForm.ship_way,
        tracking_no: this.trackingForm.tracking_no,
      };
      this.$api.order.update(this.trackingForm.order_id, params).then(() => {
        const order = _.find(this.datas, { id: this.trackingForm.order_id });
        order.tracking_no = this.trackingForm.tracking_no;
        order.ship_way = this.trackingForm.ship_way;
        this.$notify.success({
          title: '操作成功',
        });
        this.orderTabsObj.pickup.update = true;
        this.orderTabsObj.printed.update = true;
        this.orderTabsObj.unprinted.update = true;
      }).catch(error => showErrorMessage(error)).finally(() => {
        this.changeTrackingDialogVisible = false;
        loading.close();
      });
    },
    tracking(id) {
      const order = _.find(this.datas, { id });
      this.trackingDialog.order_id = id;
      this.trackingDialog.title = `${order.tracking_no}的物流信息`;

      // tracks
      this.trackings = order.tracks;
      this.trackingDialog.visible = true;
      // if (_.has(this.trackings, id)) {
      //   this.trackingDialog.visible = true;
      // } else {
      //   this.$api.order.tracking(id).then(({ data: response }) => {
      //     _.set(this.trackings, id, response);
      //     this.trackingDialog.visible = true;
      //   });
      // }
    },
  },
  components: {
    IdCardDialogButton,
  },
};
</script>

<style lang="scss" scoped>
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 5px;
    }
  }
  .text {
    text-decoration: line-through;
  }

  .operator-button {
    margin: 5px;
  }

</style>
