<template>
  <ContentWrapper id="order-issues">
    <div class="content-heading">
      全部問題訂單記錄
    </div>

    <div class="card card-default">
      <div class="card-body">
        <div class="card-header">
        </div>
        <v-client-table class="orders-table" :data="orders" :columns="tableConfigs.columns"
                        :options="tableConfigs.options">

          <template slot="user" slot-scope="props">
            <div class="flex-center">
              <el-avatar size="small" :src="props.row.user.headimgurl"></el-avatar>
              {{ props.row.user.nickname }}
            </div>
          </template>

          <template slot="parcel_model" slot-scope="props">
            {{ props.row.parcel_model.name }}
          </template>

          <div style="white-space: pre-wrap" slot-scope="scope" slot="price">
            {{ scope.row.price !== scope.row.discount_price ?
            `原價：${scope.row.price} \n 優惠價：${scope.row.discount_price}` :
            scope.row.price }}
          </div>

          <template slot="status_str" slot-scope="props">
            {{ orderStatusesObj[props.row.status] ? orderStatusesObj[props.row.status].label : '' }}
          </template>

          <template slot="post_office" slot-scope="props">
            {{ props.row.post_office ? props.row.post_office.name : '' }}
          </template>

          <template slot="operator" slot-scope="props">
            <router-link class="btn btn-primary" :to="{ name: 'order-detail', params: { id: props.row.order_id }}">
              查看訂單詳情
            </router-link>
            <button class="btn btn-primary" @click="orderRemark(props.row)" style="margin-left: 10px;">
              查看問題詳情
            </button>
            <!--<el-button type="primary" @click="printExpressLabel">Invoice</el-button>-->
          </template>

        </v-client-table>

      </div>
    </div>

    <el-dialog
        title="問題詳情"
        :visible.sync="orderRemarkDialogVisible"
        width="50%"
        :append-to-body="true"
        :show-close="false"
        :close-on-click-modal="false"
    >
      <el-form label-width="7rem" v-if="currentOrder">

        <el-form-item label="訂單問題" class="mt-4">
          <span class="ml-5" style="color: red;">{{ currentOrder.questioner_name + '：' }}</span> {{ currentOrder.issue }}
        </el-form-item>

        <template v-if="currentOrder.solution">

          <el-form-item label="已解決問題" class="mt-4">
            <span class="ml-5" style="color: red;">{{ currentOrder.solver + '：' }}</span> {{ currentOrder.solution }}
          </el-form-item>

          <el-form-item label="解決時間" class="mt-4" >
            <span class="ml-5"> {{ currentOrder.updated_at }} </span>
          </el-form-item>
        </template>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="orderRemarkDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

  </ContentWrapper>
</template>
<script>
import { mapGetters } from 'vuex';
import { getLoadingInstance, showErrorMessage } from '../../helpers';

export default {
  name: 'OrderIssues',
  mounted() {
    this.getOrders();
  },
  data() {
    return {
      currentOrders: null,
      remark: '',
      orderRemarkDialogVisible: false,
      shippingForm: null,
      currentOrder: null,
      shippingDialogVisible: false,
      tableConfigs: {
        columns: [
          'order_id',
          'parcel_code',
          'price',
          'user',
          'total_weight',
          'status_str',
          'created_at',
          'operator',
        ],
        options: {
          headings: {
            order_id: '訂單ID',
            parcel_code: '郵包號',
            price: '總價',
            user: '用戶',
            total_weight: '總重量',
            status_str: '狀態',
            created_at: '創建時間',
            operator: '操作',
          },
          filterable: false,
          sortable: [],
          perPage: 50,
        },
      },
      filterForm: {},
      filterText: '',
      props: {
        label: 'name',
        value: 'code',
        children: 'children',
      },
      checkAll: false,
      isIndeterminate: false,
      checked: [],
      orders: [],
    };
  },
  computed: {
    ...mapGetters([
      'orderStatusesObj',
    ]),
  },
  methods: {
    getOrders() {
      const loading = getLoadingInstance();
      this.$api.order.issueList().then(({ data: response }) => {
        this.orders = response.data;
      }).catch(errors => showErrorMessage(errors)).finally(() => {
        loading.close();
      });
    },
    orderRemark(order) {
      if (order) {
        this.currentOrder = order;
        this.orderRemarkDialogVisible = true;
      }
    },
  },
};
</script>
<style lang="scss">
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 5px;
    }
  }
</style>
