<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>取件訂單管理</div>
    </div>

    <div class="card card-default">
      <div class="card-body">
        <el-tabs v-model="currentTab" type="card" closable @tab-remove="removeTab" @tab-click="handleClick">
          <el-tab-pane
            v-for="item in orderTabs"
            :key="item.name"
            :label="item.title"
            :name="item.name"
          >
            <keep-alive>
              <component :is="item.component"></component>
            </keep-alive>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'OrderTabs',
  data() {
    return {
      currentTab: '',
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.addTab(val.params.tabName);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      orderTabs: state => state.pickupOrder.orderTabs,
    }),
  },
  methods: {
    ...mapMutations([
      'addPickupOrderTab',
      'removePickupOrderTab',
    ]),
    handleClick(tab) {
      this.$router.replace(`/pickup-orders/${tab.name}`);
    },
    addTab(tabName) {
      if (!~_.findIndex(this.orderTabs, { name: tabName })) {
        let title;
        switch (tabName) {
          case 'unpaid':
            title = '未支付';
            break;
          case 'paid':
            title = '已支付';
            break;
          case 'picking':
            title = '取件中';
            break;
          case 'picked':
            title = '已取件';
            break;
          // case 'unprinted':
          //   title = '未打印';
          //   break;
          // case 'printed':
          //   title = '已打印';
          //   break;
          case 'completed':
            title = '已完成';
            break;
          case 'close':
            title = '已取消';
            break;
          // case 'issue':
          //   title = '問題訂單';
          //   break;
          case 'all':
          default:
            title = '所有訂單';
            break;
        }
        this.addPickupOrderTab({
          title,
          name: tabName,
          component: tabName,
          update: false,
        });
      }
      this.currentTab = tabName;
    },
    removeTab(tabName) {
      if (this.orderTabs.length === 1) {
        return;
      }
      if (this.currentTab === tabName) {
        const tabIndex = _.findIndex(this.orderTabs, { name: tabName });
        const nextTab = this.orderTabs[tabIndex + 1] || this.orderTabs[tabIndex - 1];
        if (nextTab) {
          this.$router.replace(`/pickup-orders/${nextTab.name}`);
        }
      }
      this.removePickupOrderTab(tabName);
    },
  },
  components: {
    all: () => import(/* webpackChunkName: "order" */ './tabs/OrderAll'),
    unpaid: () => import(/* webpackChunkName: "order" */ './tabs/OrderUnpaid'),
    paid: () => import(/* webpackChunkName: "order" */ './tabs/OrderPaid'),
    picking: () => import(/* webpackChunkName: "order" */ './tabs/OrderPicking'),
    picked: () => import(/* webpackChunkName: "order" */ './tabs/OrderPicked'),
    // issue: () => import(/* webpackChunkName: "order" */ './tabs/OrderIssue'),
    close: () => import(/* webpackChunkName: "order" */ './tabs/OrderCanceled'),
    // printed: () => import(/* webpackChunkName: "order" */ './tabs/OrderPrinted'),
    // unprinted: () => import(/* webpackChunkName: "order" */ './tabs/OrderUnprinted'),
    // completed: () => import(/* webpackChunkName: "order" */ './tabs/OrderCompleted'),
  },
};
</script>
