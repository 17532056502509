<template>
  <div class="card card-default">
    <div class="card-body">
      <el-form ref="filterForm" :model="filterForm" :inline="true" class="filter-form">
        <el-form-item label="狀態" v-if="filterStatus">
          <el-select v-model="filterForm.status">
            <el-option label="所有訂單" :value="-1"></el-option>
            <el-option
              v-for="item in statuses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="id" label="訂單ID">
          <el-input v-model.number="filterForm.id" clearable />
        </el-form-item>

        <!--<el-form-item prop="parcel_code" label="郵包號">-->
          <!--<el-input v-model.number="filterForm.parcel_code" clearable />-->
        <!--</el-form-item>-->

        <!--<el-form-item prop="tracking_no" label="運單號">-->
          <!--<el-input v-model.number="filterForm.tracking_no" clearable />-->
        <!--</el-form-item>-->

        <!--<el-form-item prop="post_office_id" label="所屬門店">-->
          <!--<el-select v-model="filterForm.post_office_ids" multiple filterable  style="width: 280px">-->
            <!--<el-option-->
              <!--v-for="postOffice in currentUser.post_offices"-->
              <!--:key="postOffice.id"-->
              <!--:value="postOffice.id"-->
              <!--:label="postOffice.name"-->
            <!--&gt;-->
            <!--</el-option>-->
          <!--</el-select>-->
        <!--</el-form-item>-->

        <!--<el-form-item prop="parcel_model_id" label="郵包模型">-->
          <!--<el-select v-model="filterForm.parcel_model_ids" multiple>-->
            <!--<el-option-->
              <!--v-for="parcelModel in parcelModels"-->
              <!--:key="parcelModel.id"-->
              <!--:value="parcelModel.id"-->
              <!--:label="parcelModel.name"-->
            <!--&gt;-->
            <!--</el-option>-->
          <!--</el-select>-->
        <!--</el-form-item>-->

        <el-form-item prop="date" label="訂單日期">
          <el-date-picker
            v-model="filterForm.date"
            type="daterange"
            align="right"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>

        <div class="row">
          <div class="col text-center">
            <el-button type="primary" @click="fetchOrder">篩選</el-button>
          </div>
        </div>

      </el-form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { getLoadingInstance, showErrorMessage } from '../../helpers';

export default {
  name: 'OrderFilter',
  props: {
    status: {
      type: Number,
      default: -1,
    },
    name: {
      type: String,
      default: '',
    },
    printed: {
      type: Number,
      default: -1,
    },
    issue: Boolean,
    filterStatus: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // if (!this.parcelModels.length) {
    //   this.$store.dispatch('fetchParcelModels');
    // }
    this.fetchOrder();
  },
  data() {
    return {
      filterForm: {
        name: this.name,
        status: this.status,
        issue: this.issue,
        printed: this.printed,
        id: '',
        parcel_code: '',
        tracking_no: '',
        post_office_ids: [],
        parcel_model_ids: [],
        date: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近一個月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近三個月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      statuses: state => state.pickupOrder.statuses,
    }),
    ...mapGetters(['currentUser']),
  },
  methods: {
    fetchOrder() {
      const params = _.pickBy(this.filterForm, (value) => {
        if (_.isArray(value)) return value.length;
        if (_.isNumber(value)) return ~value;
        return Boolean(value);
      });
      const loading = getLoadingInstance();
      return this.$api.pickupOrder.get(params).then(({ data: response }) => {
        this.$emit('returnOrders', response.data);
      }).catch(errors => showErrorMessage(errors)).finally(() => {
        loading.close();
      });
    },
  },
};
</script>

<style lang="scss">

  .filter-form {
    .el-date-editor {
      .el-range-separator {
        width: 8%;
      }
    }

    .el-form-item {
      margin-left: 80px;
    }
  }
</style>
