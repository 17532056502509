<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>訂單拆分</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <el-form ref="form" :model="form" :rules="formRules" label-width="6rem" @submit.native.prevent>
          <el-form-item label="郵包號" prop="parcel_code">
            <el-input
              ref="parcelCodeInput"
              v-model="form.parcel_code"
              placeholder="請掃描/輸入郵包號獲取訂單"
              clearable
              autofocus
              :disabled="order !== null"
              @keyup.enter.native="getOrder"
              @clear="init"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="row" v-if="order">
      <div class="col-sm-12 col-lg-4">
        <div class="card card-default h-100">
          <div class="card-header">
            <div class="card-title">主訂單信息</div>
          </div>
          <div class="card-body">
            <order-info-table class="table-striped" :order="order"></order-info-table>
            <el-button type="primary" class="mt-2" @click="openOrderDialog">添加子郵包</el-button>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-8">
        <div class="card card-default h-100">
          <div class="card-header">
            <div class="card-title">主訂單商品</div>
          </div>
          <div class="card-body">
            <order-items-table
              class="table-striped"
              :order="order"
              @updateOrderItem="handleUpdateOrderItem"
              @deleteOrderItem="handleDeleteOrderItem"
            />
          </div>
        </div>
      </div>
    </div>

    <template v-if="subOrders.length">
      <div class="row mt-3" v-for="subOrder in subOrders" :key="subOrder.id">
        <div class="col-sm-12 col-lg-4">
          <div class="card card-default h-100">
            <div class="card-header">
              <div class="card-title">子訂單信息</div>
            </div>
            <div class="card-body">
              <order-info-table class="table-striped" :order="subOrder"></order-info-table>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-8">
          <div class="card card-default h-100">
            <div class="card-header">
              <div class="card-title">子訂單商品</div>
            </div>
            <div class="card-body">
              <order-items-table
                class="table-striped"
                :order="subOrder"
                @updateOrderItem="handleUpdateOrderItem"
                @deleteOrderItem="handleDeleteOrderItem"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <el-dialog
      class="order-split-dialog"
      v-if="order"
      title="添加子郵包"
      :visible.sync="subOrderDialogVisible"
      width="60%"
      :append-to-body="true"
      @open="initSubOrderForm"
    >
      <el-form ref="subOrderForm" :model="subOrderForm" :rules="subOrderFormRules" label-width="6rem" @submit.native.prevent>
        <el-form-item prop="parcel_code" label="郵包號">
          <el-input v-model="subOrderForm.parcel_code" placeholder="请输入郵包號" clearable />
        </el-form-item>
        <el-form-item prop="parcel_model_id" label="郵包類型">
          <el-select v-model="subOrderForm.parcel_model_id" clearable class="w-100" @change="setMaxDiscountPrice">
            <el-option
              v-for="parcel_model in parcelModels"
              :key="parcel_model.id"
              :label="parcel_model.name"
              :value="parcel_model.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="價格" prop="discount_price" v-if="subOrderForm.parcel_model_id">
          <el-input-number
            v-model="subOrderForm.discount_price"
            :min="0"
            :max="max_discount_price"
            :precision="2"
          />
        </el-form-item>
        <el-form-item label="訂單商品" prop="item_ids">
          <el-transfer
            v-model="subOrderForm.item_ids"
            :data="order.order_items"
            :titles="['主訂單', '子訂單']"
            :props="{
              key: 'id',
              label: 'product_name',
            }"
            :render-content="renderTransferLabel"
          ></el-transfer>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="subOrderDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addSubOrder">確定</el-button>
      </span>
    </el-dialog>
  </ContentWrapper>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { getLoadingInstance, showErrorMessage, formatMoney } from '@/helpers';
import OrderInfoTable from '@/components/Order/OrderInfoTable';
import OrderItemsTable from '@/components/Order/OrderItemsTable';

export default {
  name: 'OrderSplit',
  data() {
    return {
      subOrderDialogVisible: false,
      form: {
        parcel_code: '',
      },
      formRules: {
        parcel_code: [
          { required: true, message: '請輸入郵包號', trigger: 'blur' },
        ],
      },
      order: null,
      subOrders: [],
      subOrderForm: {
        parent_id: null,
        parcel_code: '',
        parcel_model_id: null,
        discount_price: null,
        item_ids: [],
      },
      max_discount_price: 99999,
      subOrderFormRules: {
        parcel_code: { required: true, message: '請輸入郵包號', trigger: 'blur' },
        parcel_model_id: { required: true, message: '請選擇郵包類型', trigger: 'change' },
        discount_price: { required: true },
        item_ids: { required: true, message: '最少選擇一個商品移動到子訂單', trigger: 'change' },
      },
    };
  },
  mounted() {
    this.$store.dispatch('fetchParcelModels');
    this.init();
  },
  computed: {
    ...mapState({
      parcelModels: state => state.parcel.models,
    }),
  },
  watch: {
    '$route.params.parcel_code': {
      handler() {
        this.init();
      },
    },
  },
  methods: {
    init() {
      if (this.$route.params.parcel_code) {
        this.form.parcel_code = this.$route.params.parcel_code;
        this.getOrder();
      } else {
        this.form.parcel_code = '';
        this.order = null;
        this.subOrders = [];
        this.$refs.parcelCodeInput.focus();
      }
    },
    initSubOrderForm() {
      const num = this.order.sub_order_ids.length + 1;
      this.subOrderForm.parent_id = this.order.id;
      this.subOrderForm.parcel_code = `${this.order.parcel_code}-${num}`;
      this.subOrderForm.parcel_model_id = this.order.parcel_model.id;
      this.subOrderForm.item_ids = [];
      this.setMaxDiscountPrice(this.order.parcel_model.id);
    },
    getOrder() {
      this.order = null;
      this.subOrders = [];
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          this.$api.order.getByParcelCode(this.form.parcel_code).then(({ data: response }) => {
            this.subOrders = [];
            this.order = response.data;
            this.$nextTick(() => {
              if (this.order.issue) {
                // this.$notify.warning({
                //   title: '這個是問題訂單',
                // });
                // this.init();
              } else {
                // 獲取子訂單
                this.order.sub_order_ids.forEach((id) => {
                  this.$api.order.getById(id).then(({ data: res }) => {
                    this.subOrders.push(res.data);
                  });
                });
              }
            });
          }).catch((errors) => {
            this.order = null;
            showErrorMessage(errors);
            this.form.parcel_code = '';
            this.$refs.parcelCodeInput.focus();
          }).finally(() => {
            loading.close();
          });
        }
      });
    },
    handleUpdateOrderItem(item) {
      const index = _.findIndex(this.order.order_items, { id: item.id });
      if (index !== -1) {
        this.order.order_items[index] = item;
      } else {
        this.order.order_items.push(item);
      }
    },
    handleDeleteOrderItem(id) {
      this.order.order_items = _.reject(this.order.order_items, { id });
    },
    setMaxDiscountPrice(parcel_model_id) {
      const parcelModel = _.find(this.parcelModels, { id: parcel_model_id });
      this.subOrderForm.discount_price = parcelModel.price;
      this.max_discount_price = parcelModel.price;
    },
    openOrderDialog() {
      if (this.order.order_items.length <= 1) {
        this.$notify.warning({
          message: '訂單只有一個商品不能拆分',
        });
      } else {
        this.subOrderDialogVisible = true;
      }
    },
    addSubOrder() {
      this.$refs.subOrderForm.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          this.$api.order.createSubOrder(this.subOrderForm).then(() => {
            this.$notify.success({
              message: '已創建子訂單',
            });
            this.subOrderDialogVisible = false;
            this.getOrder();
          }).catch((errors) => {
            showErrorMessage(errors);
          }).finally(() => {
            loading.close();
          });
        }
      });
    },
    renderTransferLabel(h, option) {
      return <span>
        { option.product_name } / { option.product_count }{ option.unit } /&nbsp;
        { formatMoney(option.price, option.currency) } / { option.gross_weight }KG
      </span>;
    },
  },
  components: {
    OrderInfoTable, OrderItemsTable,
  },
};
</script>

<style lang="scss">
  .order-split-dialog {
    .el-transfer-panel {
      width: 40%;
    }
  }
</style>
