<template>
  <section>
    <div class="operators">
    <el-checkbox
      v-if="selectable"
      border
      :indeterminate="isIndeterminate"
      v-model="checkAll"
      @change="handleCheckAllChange"
    >全选</el-checkbox>
      <el-button type="primary" class="ml-2" :disabled="!checked.length" @click="exportData" v-if="exportable">
        導出數據
      </el-button>
    </div>

    <v-client-table class="mt-2" :data="datas" :columns="tableConfigs.columns" :options="tableConfigs.options">

      <template v-slot:child_row="props">
        <el-card>
          <div class="row">
            <div class="col" v-if="props.row.address">
              <h5>寄件人</h5>
              <table class="table">
                <tbody>
                  <tr>
                    <th>姓名</th>
                    <td>{{ props.row.address.contact }}</td>
                  </tr>
                  <tr>
                    <th>電話</th>
                    <td>{{ props.row.address.phone }}</td>
                  </tr>
                  <tr>
                    <th>地址</th>
                    <td>{{ props.row.address.pickup_address }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-card>

        <el-card class="mt-3">
          <div slot="header" class="clearfix">訂單列表</div>
          <table class="table table-striped">
            <tbody>
            <tr>
              <th>郵包號</th>
              <th>狀態</th>
              <th></th>
              <!--<th>價格</th>-->
              <!--<th>淨重</th>-->
              <!--<th>毛重</th>-->
            </tr>
            <tr v-for="item in props.row.orders" :key="item.id">
              <td>
                {{ item.parcel_code }}
              </td>
              <td>
                {{ orderStatusesObj[item.status] ? orderStatusesObj[item.status].label : '' }}
              </td>
              <td>
                <router-link class="btn btn-primary" :to="{ name: 'pickup-order-detail', params: { id: item.id }}">
                  詳情
                </router-link></td>
              <!--<td>{{ item.currency }}{{ item.price }}</td>-->
              <!--<td>{{ item.net_weight }}</td>-->
              <!--<td>{{ item.gross_weight }}</td>-->
            </tr>
            </tbody>
          </table>
        </el-card>
      </template>

      <template slot="user" slot-scope="props">
        <div class="flex-center">
          <el-avatar size="small" :src="props.row.user.headimgurl"></el-avatar>
          {{ props.row.user.nickname }}
        </div>
      </template>

      <template slot="user_time" slot-scope="props">
        {{ props.row.date }}  {{props.row.time_period}}
      </template>

      <template slot="price" slot-scope="props">
        {{ props.row.price | currency }}
      </template>

      <template slot="total_weight" slot-scope="props">
        {{ props.row.total_weight ? props.row.total_weight + 'KG' : '未稱重' }}
      </template>

      <template slot="order_count" slot-scope="props" v-if="props.row.orders">
        {{ props.row.orders.length }}
      </template>

      <template slot="selectable" slot-scope="props">
        <el-checkbox v-model="checked" :label="props.row.id" @change="handleCheckedChange">
          <span></span>
        </el-checkbox>
      </template>

      <template slot="status" slot-scope="props">
        {{ pickupOrderStatusesObj[props.row.status] ? pickupOrderStatusesObj[props.row.status].label : '' }}
      </template>

      <template slot="operation" slot-scope="props">
        <el-popconfirm title="確定要關閉這個訂單？" @confirm="closeOrder(props.row.id)">
          <el-button
              size="small"
              type="danger"
              slot="reference"
              class="operator-button mr-2"
              v-if="props.row.status <= 2"
          >
            取消訂單（退款）
          </el-button>
        </el-popconfirm>
        <el-popconfirm title="確定要關閉這個訂單？" @confirm="closeOrder(props.row.id, true)">
          <el-button
              size="small"
              type="danger"
              slot="reference"
              class="operator-button mr-2"
              v-if="props.row.status <= 2"
          >
            關閉訂單（不退款）
          </el-button>
        </el-popconfirm>
        <router-link class="el-button operator-button el-button--primary el-button--small text-decoration-none"
                     target="_blank" :to="{ name: 'pickup-order-detail', params: { id: props.row.id }}">詳細</router-link>
        <!--<el-button type="primary" size="small" class="operator-button"-->
                   <!--@click="$router.push({ name: 'pickup-order-detail', params: { id: props.row.id }})">詳細</el-button>-->
        <el-button
          size="small"
          class="operator-button"
          @click="orderPicked(props.row.id)"
          v-if="props.row.status === 2"
        >確認取件</el-button>
        <el-button
          size="small"
          class="operator-button"
          @click="orderPicking(props.row.id)"
          v-if="props.row.status === 1"
        >安排取件</el-button>
      </template>

    </v-client-table>

    <el-dialog
        id="picking"
        :visible.sync="pickingDialogVisible"
        title="安排取件"
        :append-to-body="true"
    >
      <el-form ref="pickingForm" :model="pickingForm" :rules="pickingFormRules" label-width="10rem">
        <el-form-item label="用戶備註">
           {{ pickingForm.remark }}
        </el-form-item>
        <el-form-item label="用戶填寫取件時間">
           {{ pickingForm.time }}
        </el-form-item>

        <el-form-item prop="pickup_time" label="安排取件時間">
          <el-date-picker
              v-model="pickingForm.pickup_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="取件備註">
          <el-input v-model="pickingForm.admin_remark" placeholder="請輸入取件備註（可選）" />
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pickingDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="orderPickingConfirm">確定</el-button>
      </span>
    </el-dialog>

  </section>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import download from 'downloadjs';
import { getLoadingInstance, showErrorMessage } from '@/helpers';
import appConfig from '@/app.config';

export default {
  name: 'OrderTable',
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default: () => {},
    },
    selectable: Boolean,
    exportable: Boolean,
    handleIssue: Boolean,
    removeIssue: Boolean,
  },
  data() {
    return {
      datas: [],
      checked: [],
      checkAll: false,
      isIndeterminate: true,
      trackingDialog: {
        order_id: null,
        title: '',
        visible: false,
      },
      trackings: {},
      orderIssueDialogVisible: false,
      currentOrders: [],
      issue: '',
      solution: '',
      issueType: 1,
      pickingFormRules: {
        pickup_time: { required: true, message: '請選擇安排取件日期', trigger: 'blur' },
      },
      pickingDialogVisible: false,
      pickingForm: {
        remark: '',
        admin_remark: '',
        time: '',
        pickup_time: '',
      },
      pickerOptions: {
        disabledDate(date) {
          const zero = new Date().setHours(0, 0, 0, 0);
          if (date.getTime() < zero) {
            return true;
          }
          return false;
        },
      },
    };
  },
  watch: {
    data(val) {
      this.datas = val;
    },
  },
  computed: {
    ...mapGetters([
      'pickupOrderStatusesObj',
      'orderStatusesObj',
    ]),
    tableConfigs() {
      let columns;
      if (this.selectable) {
        columns = ['selectable', ...this.columns];
      } else {
        columns = [...this.columns];
      }
      return {
        columns,
        options: {
          headings: {
            selectable: '',
            id: '訂單ID',
            price: '價格',
            user: '用戶',
            user_time: '用戶填寫取件時間',
            remark: '備註',
            order_count: '包含訂單數量',
            pickup_time: '安排取件時間',
            admin_remark: '安排取件備註',
            status: '狀態',
            created_at: '創建時間',
            cancel_time: '關閉時間',
            operation: '操作',
          },
          filterable: false,
          perPage: 100,
          ...this.options,
        },
      };
    },
  },
  methods: {
    ...mapMutations(['setPickupOrderTabUpdate']),
    exportData() {
      const loading = getLoadingInstance();
      const { API_HOST } = appConfig;
      const formData = new FormData();
      _.forEach(this.checked, id => formData.append('ids[]', id));
      const url = `${API_HOST}/pickup-order-export`;
      const x = new XMLHttpRequest();
      x.open('POST', url, true);
      x.responseType = 'blob';
      x.onload = function (e) {
        loading.close();
        download(e.target.response, '取件訂單列表.xls', e.target.response.type);
      };
      x.send(formData);
    },
    handleCheckAllChange(val) {
      this.checked = val ? _.map(this.datas, 'id') : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange() {
      const checkedCount = this.checked.length;
      this.checkAll = checkedCount === this.datas.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.datas.length;
    },
    closeOrder(id, force = false) {
      if (force) {
        const loading = getLoadingInstance();
        this.$api.pickupOrder.forceClose(id).then(() => {
          this.datas = _.reject(this.datas, { id });
          this.$notify.success({
            title: '操作成功',
          });
        }).catch(error => showErrorMessage(error)).finally(() => {
          this.orderIssueDialogVisible = false;
          loading.close();
        });
      } else {
        const loading = getLoadingInstance();
        this.$api.pickupOrder.close(id).then(() => {
          this.datas = _.reject(this.datas, { id });
          this.$notify.success({
            title: '操作成功',
          });
        }).catch(error => showErrorMessage(error)).finally(() => {
          this.orderIssueDialogVisible = false;
          loading.close();
        });
      }
    },
    orderPicked(id) {
      const loading = getLoadingInstance();
      this.$api.pickupOrder.update(id, { action: 'picked' }).then(() => {
        this.datas = _.reject(this.datas, { id });
        this.setPickupOrderTabUpdate({ name: 'picked', update: true });
        this.$notify.success({
          title: '操作成功',
        });
      }).catch(error => showErrorMessage(error)).finally(() => {
        loading.close();
      });
    },
    orderPicking(id) {
      this.pickingDialogVisible = true;
      const order = _.find(this.datas, { id });
      this.pickingForm = {
        id,
        remark: order.remark,
        admin_remark: order.remark,
        time: `${order.date}  ${order.time_period}`,
        pickup_time: null,
      };
    },
    orderPickingConfirm() {
      this.$refs.pickingForm.validate((valid) => {
        if (valid && this.pickingForm.id) {
          const loading = getLoadingInstance();
          const params = {
            action: 'picking',
            admin_remark: this.pickingForm.admin_remark,
            pickup_time: this.pickingForm.pickup_time,
          };
          this.$api.pickupOrder.update(this.pickingForm.id, params).then(() => {
            this.datas = _.reject(this.datas, { id: this.pickingForm.id });
            this.setPickupOrderTabUpdate({ name: 'picking', update: true });
            this.$notify.success({
              title: '操作成功',
            });
          }).catch(error => showErrorMessage(error)).finally(() => {
            this.pickingDialogVisible = false;
            loading.close();
          });
        }
      });
    },
  },
  components: {
    // IdCardDialogButton,
  },
};
</script>

<style lang="scss">
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 5px;
    }
  }
  .text {
    text-decoration: line-through;
  }

  .operator-button {
    margin-top: 10px;
  }

  #picking {
    .el-date-editor {
      .el-input__prefix {
        left: 8px!important;
      }
    }
  }

</style>
