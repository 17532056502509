<template>
  <el-button
    type="primary"
    size="mini"
    @click="openIdCardDialog(order.receiver_address)"
  >
    <slot>查看圖片</slot>
    <el-dialog
      :title="idCardViewerDialog.title"
      :visible.sync="idCardViewerDialog.visible"
      width="50%"
      :append-to-body="true"
      @close="closeIdCardDialog"
    >
      <h4 class="text-center">身份證號碼：{{ idCardViewerDialog.card_no }}</h4>
      <div class="row">
        <div class="col text-center">
          <h5>正面</h5>
          <el-image :src="idCardViewerDialog.card_file_url"></el-image>
        </div>
        <div class="col text-center">
          <h5>背面</h5>
          <el-image :src="idCardViewerDialog.opposite_file_url"></el-image>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeIdCardDialog">關閉</el-button>
      </span>
    </el-dialog>
  </el-button>
</template>

<script>
export default {
  name: 'IdCardDialogButton',
  props: {
    order: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      idCardViewerDialog: {
        visible: false,
        card_no: '',
        title: '',
        card_file_url: '',
        opposite_file_url: '',
      },
    };
  },
  methods: {
    openIdCardDialog(address) {
      this.idCardViewerDialog = {
        visible: true,
        title: `${address.name}的身份證圖片`,
        card_no: address.card_no,
        card_file_url: address.card_file_url,
        opposite_file_url: address.opposite_file_url,
      };
    },
    closeIdCardDialog() {
      this.idCardViewerDialog = {
        visible: false,
        card_no: '',
        title: '',
        card_file_url: '',
        opposite_file_url: '',
      };
    },
  },
};
</script>
